.gs-modal
	.ant-modal-header
		padding: 16px
		.ant-modal-title
			font-weight: normal
	.ant-modal-body
		display: flex
		flex-direction: column
		align-items: center
		justify-content: space-between
		margin: 0 16px
		padding: 24px 16px
		form
			width: 100%
	.ant-modal-footer
		display: flex
		align-items: center
		justify-content: flex-end
		gap: 8px
		padding: 16px
		.ant-btn-default
			border: none
		.ant-btn-primary:not(:disabled)
			background: #0065B3
			border-radius: 4px
			height: 35px
			&:hover:not(:disabled)
				background: #045594 !important
	#import-form
		.ant-upload
			height: 185px
			
	.modal-list-empty
		margin: 60px auto 20px
		display: flex
		flex-direction: column
		align-items: center
		row-gap: 5px
