.top-bar-main-content
	display: flex
	justify-content: space-between
	width: 100%
	height: 48px
	margin: auto
	background: #E9E9E9
	border-bottom: 1px solid #D7D7D7

	.ant-btn svg
		font-size: 1.4rem
		color: #0065B3

	.top-bar-left-content
		display: flex
		align-items: center
		justify-content: flex-start
		margin: 1rem 0 1rem 0

		button
			border: 0
			border-radius: 0 20px 20px 0
			background: #DCE7EF
			padding: 4px 4px 4px 0
			width: 2.5rem

		img
			width: 117px
			filter: brightness(0) saturate(100%) invert(32%) sepia(76%) saturate(6191%) hue-rotate(192deg) brightness(86%) contrast(98%)
			margin: 0 0 0 1rem

	.top-bar-right-content
		
		.btn-user-content
			padding-left: 16px
			padding-right: 16px
			height: 100%
			background-color: #dbdbdb

		.buttons-content
			display: flex
			align-items: center
			height: 100%
			gap: 20px
			
			.ant-badge
				>span
					display: flex
					justify-content: center
					align-items: center

			button
				width: 27px
				height: 27px
				border-radius: 8px
				margin-left: 10px
				display: inline-flex
				justify-content: center
				align-items: center
				padding: 0
				background: transparent

				svg
					font-size: 18px

				>span
					width: inherit
					height: inherit
					display: inline-flex
					justify-content: center
					align-items: center

					sup
						min-width: 14px
						height: 14px
						line-height: 13px
						font-size: 9px
						padding: 0 4px
						background-color: #B60000

					>span
						background: transparent

	


