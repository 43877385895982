body
	margin: 0
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	-webkit-font-smoothing: antialiased
	user-select: none

code
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

#root
	height: 100%

::-webkit-scrollbar
	width: 10px
	height: 10px

::-webkit-scrollbar-track
	background: #f0f0f0
	border-radius: 4px

::-webkit-scrollbar-thumb
	border-radius: 4px
	background: #D9D9D9

::-webkit-scrollbar-thumb:hover
	background: rgba(0, 0, 0, 0.4)

.chart-tooltip-parent
	text-align: center
	padding: 5px 0

	div
		margin-top: 5px
		text-align: center

.ant-modal-content
	user-select: none

.microfront_overlay
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	z-index: 10000
	opacity: 0.4
	background-color: #000
	width: 100%
	height: 100%

.hidden
	display: none

.bullet
	height: 5px
	width: 5px
	background-color: #000
	border-radius: 50%
	display: inline-block

.gs-flex
	display: flex
	&.flex-col
		flex-direction: column
	&.flex-row
		flex-direction: row
	&.align-center
		align-items: center
	&.align-start
		align-items: start
	&.align-end
		align-items: end
	&.jc-sb
		justify-content: space-between
	&.jc-center
		justify-content: center
	&.jc-start
		justify-content: start
	&.jc-end
		justify-content: end
	&.w-full
		width: 100%
	&.h-full
		height: 100%
