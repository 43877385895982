.projection-date-tag
  display: inline-flex
  align-items: center
  background-color: rgba(46, 182, 104, 0.12)
  height: 24px
  padding: 4px 8px
  margin-right: 40px
  gap: 8px
  border-radius: 4px

  .calendar-icon
    font-size: 12px
    color: rgba(39, 144, 51, 1)

  .date-text
    color: #2EB668
    font-weight: 400
    font-size: 12px
    line-height: 16.34px
    white-space: nowrap

  .date-text-bold
    font-weight: 600
