.recurring-contract-page
	.gs-multiple-search
		.ant-input-affix-wrapper
			width: 235px
			height: 32px
	.period-base-selected
		display: flex
		flex-direction: column
		margin-right: 50px
		padding: 10px 0
		p
			margin: 0
	.empty-date-page
		display: flex
		flex-direction: column
		height: 600px
		align-content: center
		justify-content: center
		flex-wrap: wrap
		img
			width: 400px
			align-self: center
			margin-bottom: 20px
		button
			width: 20%
			align-self: center
	.header-content
		width: 100%
		flex-direction: column
		align-items: flex-start
		.container-header
			justify-content: space-between
		.container-header-left
			align-content: center
		.items-header-left
			display: flex
			align-items: center
			margin-right: 17px
		.select-search
			width: 225px
		.search-field
			width: 240px
		.gs-top-buttons
			.order-redefinition
				order: -1
		.disabled-button
			cursor: not-allowed !important
			color: rgba(0, 0, 0, 0.25) !important
			border-color: #d9d9d9 !important
			background: #f5f5f5 !important
			text-shadow: none !important
			box-shadow: none !important
		.ant-row
			width: 100%
		.title-content
			justify-content: space-between
			width: 100%
			.page-title-content
				width: auto
	main,.value-view-content
		padding: 24px
	.value-view-content
		.search-table
			justify-content: end
		.ant-input-wrapper
			display: flex
			justify-content: end
			padding: 0 30px
		.gs-tag
			display: flex
			align-items: center
			justify-content: center
			gap: 8px
			.date-period
				font-weight: 650
			p
				margin: 0
		.header-title
			margin-bottom: 15px
			justify-content: space-between
			.page-title-content
				padding: 0
				width: auto !important
		.header-info
			margin-bottom: 15px
			.date-period
				font-weight: 400
			.gs-return-button
				margin-right: 40px
			.header-info-tag
				display: flex
				flex-direction: column
				p
					margin: 0
		.gs-top-buttons
			background: #FCFCFC
			width: auto
			.top-buttons-left-content
				margin: 0
.new-contract-form
	.editable-cell-value-contract-modal
		display: flex
		justify-content: center
		svg
			opacity: 0

	.editable-cell-value-contract-modal:hover
		svg
			opacity: 1
	.disabled-cell
		cursor: not-allowed

	>.ant-row>.ant-collapse
		width: 100%
	.gs-tab
		.ant-tabs-tab
			width: auto
	.ant-collapse-header
		padding-left: 0 !important
	.ant-collapse-content-box
		padding-left: 0
		padding-right: 0
	.ant-table-content
		overflow: auto!important
		max-height: 300px !important
		.editable-cell-value-wrap
			padding: 0 !important
			align-self: center
		p
			margin: 0
		.format-negative-number-col
			color: #920000
	.ant-table-thead
		position: sticky
		top: 0
		z-index: 1
	.flex-field-content
		margin-top: 10px
		max-height: 300px
		overflow-y: auto
		border-top: 1px solid #F1F2F3
		margin-left: -32px !important
		margin-right: -32px !important
		padding: 20px 32px 0
	.gs-tab
		button
			padding: 0
		.flex-field-content
			border-top: none
			margin-top: 10px
			padding: 0 32px 0
	.ant-input-number-affix-wrapper
		width: 100%
	.gs-input-numeric-format
		width: 120px
	.ant-picker-suffix
		order: -1
		svg
			color: #5C5C5C
	.replicate-scenario-month
		width: -webkit-fill-available
	.ant-picker-disabled
		input,span
			opacity: 0.5

#recurring-contract-exporter-popover-container
	&>.ant-popover-inner-content
		width: 100%

		&>.ant-row>.ant-tabs
			width: 100%

			.ant-tabs-content-holder
				.ant-input
					margin-bottom: 16px

.recurring-contract-buttons
	display: flex
	justify-content: space-between
	align-items: center
	
.dropdown-render-calculate
	padding: 8px
	width: 200px
	background: white

.dropdown-render-calculate-content
	display: flex
	flex-direction: column
	gap: 8px

.dropdown-render-calculate-content-item
	display: flex
	align-items: center

.dropdown-render-calculate-space
	display: flex
	justify-content: center
	margin-top: 8px
