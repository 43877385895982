#flex-field-module
	max-height: 32px

.flex-field-table
	padding: 0 16px
	.ant-table-row:hover
		.field-column svg
			opacity: 0.8

	.field-column
		display: flex
		align-items: center
		justify-content: center
		gap: 5px
		position: relative

		&:hover
			opacity: 1

		svg
			font-size: 1rem
			opacity: 0
			position: absolute
			right: 0
			top: 4px

		p
			margin: 0

.first-container-input-content
	display: flex
	gap: 16px
	justify-content: space-between

	.first-input-container
		width: 50%

.input-selects-content
	display: flex
	justify-content: space-between

	.first-input-container
		width: 49%

	.input-content
		width: 23.5%

	.container-input
		width: 23.5%

.footer-container
	display: flex
	justify-content: space-between

	.inputs-container-checkbox
		display: flex
		gap: 16px

		.ant-form-item
			margin: 0

		.input-content-checkbox-container
			display: flex
			align-items: center

.input-filters-container
	display: flex
	gap: 2%

	.select-filters
		width: 23%

.flex-field-modal-container
	.ant-modal-body
		display: block
		.ant-row
			gap: 16px
			.ant-col
				padding: 0 !important
		.ant-row:nth-child(1)
			.ant-col
				flex: none
				flex-grow: 1
	.input-selects-content
		display: flex
		gap: 16px

	.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item
		float: left
		margin-right: 8px

	.input-content-checkbox-container > .ant-row.ant-form-item
		float: left

	.flex-field-added-container
		float: left
		width: 100%

	.flex-field-icon
		border: none
		background: #FCFCFC

	.ant-btn-primary
		color: white
		&:disabled
			background: #F5F5F5
			border: 1px solid #DBDBDB
			color: #ADADAD
			&:hover
				background: #F5F5F5 !important
				border: 1px solid #DBDBDB !important
				color: #ADADAD !important

.flex-field-list-container
	border-top: 1px solid #cccccc
	margin-top: 20px
	min-height: 120px
	width: 100%

	.flex-field-list-header
		.ant-row
			width: 100%
			border: none
			height: 22px
			font-weight: 500
			margin-top: 10px
			padding: 0 0 0 10px
			.ant-col
				padding-left: 8px

	.flex-field-list-content
		max-height: 250px
		overflow: auto
		padding-left: 1px
		.ant-row
			width: 100%
			height: 32px
			margin-top: 6px
			border: 1px solid #ccc
			border-radius: 4px
			overflow: hidden
			.ant-col
				display: flex
				align-items: center
			.edit-button
				font-size: 17px
				margin-top: 6px
			button
				height: 29px
				border: none
				padding: 0
				background: transparent
				margin-right: -10px
			svg.delete-icon:hover
				color: #FF4D4F
				cursor: pointer

.flex-field-top-buttons
	justify-content: space-between
	align-items: center
	display: flex
	margin-right: 24px
	.gs-top-buttons
		flex: 1
	.gs-main-button,
	.custom-button
		order: -1

	.custom-button
		border: 1px solid #0D2F48
		margin-left: 1rem
		border-radius: 4px
		font-weight: 600
		height: 35px

.gs-table

	.ant-table-cell
		padding-left: 5px
