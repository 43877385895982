.bank-conciliation-widget
	display: flex
	flex-direction: column

	.ant-row
		height: 4rem

		&:not(:last-child)
			margin-bottom: 12px

	.col-banks
		width: 20%
		max-width: none
		flex: none

	.bank-conciliation-content
		display: flex
		flex-direction: column

		.badge
			height: 100%
			border-radius: 9px

		.bank-conciliation-header

			.badge
				display: flex
				background: #f7f7f7

				.loading
					svg
						-webkit-animation: loadingCircle 1s infinite linear
								animation: loadingCircle 1s infinite linear

				svg
					color: #4F9ACF

		.ant-table-container
			.ant-table-header
				.ant-table-cell
					padding: 5px
					border-bottom: none
					background: #fff

					&::before
						display: none

			.ant-table-body
				overflow-y: auto!important

				table
					border-spacing: 0 5px

					.ant-table-row
						&:nth-child(2n)
							.ant-table-cell
								background-color: #f4f8fb

						&:nth-child(2n+1)
							.ant-table-cell
								background-color: #f1f3f4

						.ant-table-cell
							padding: 5px

							&:first-child
								border-top-left-radius: 5px
								border-bottom-left-radius: 5px
								border: 1px solid #eaebeb
								border-right: none

							&:not(:first-child):not(:last-child)
								border: 1px solid #eaebeb
								border-left: none
								border-right: none

							&:last-child
								border-top-right-radius: 5px
								border-bottom-right-radius: 5px
								border: 1px solid #eaebeb
								border-left: none
