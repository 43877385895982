.gs-modal .ant-modal-body
	align-items: inherit !important 
	
.transfer-form-container
	display: flex
	align-items: center
	.transfer-form-inputs-content
		display: flex
		gap: 20px
		justify-content: space-between
		
	form
		.add-list
			display: flex
			justify-content: end
			& > button
				height: 35px
		.gs-tag
			width: fit-content
		.row-items
			> .ant-col
				width: 25%

.transfer-list
	.cost-category-list-header
		padding: 0 12px

	.icon-transfer
		display: flex !important
		svg
			height: 46%
			width: 46%

.collaborator-label
	font-weight: 500
	margin-bottom: 5px