.gs-input-label
	width: 100%
	font-weight: 500
	input
		margin-top: 5px

.gs-search
	width: 240px
	background: #E8F2F9
	border-color: #E8F2F9
	input
		background: #E8F2F9
		color: #5C5C5C

	span
		margin-right: 8px
	svg
		font-size: 16px
		color: #0066B6

.ant-input-affix-wrapper-focused.gs-search
	svg
		color: #40a9ff

.gs-input-numeric-format
	width: 60px
	border: 1px solid #d7d7d7
	border-radius: 4px
	padding: 4px 11px
	&:focus-visible
		outline: none
		border: 1px solid #d7d7d7
