.cards-skeleton-container
    align-self: start
    .ant-card-body
        padding: 0
        width: 100%
        display: flex
        justify-content: space-between
    .ant-card-body::after,
    .ant-card-body::before 
        content: unset
    .filter-card-skeleton
        width: 240px
        height: 45px
        padding: 10px
        margin: 0
        display: flex
        align-items: center
        .ant-card-body
            padding: 0
            width: 100%
            display: flex
            justify-content: space-between
        .ant-card-body::after,
        .ant-card-body::before 
            content: unset
