#summary-cards-container
	display: flex
	align-items: center
	justify-content: center
	gap: 8px

#card-container
	display: flex
	align-items: center
	justify-content: center
	min-width: 175px
	border: 1px solid #ccc
	border-radius: 4px
	padding: 8px 15px
	svg
		margin-right: 12px
		font-size: 24px
		color: #4F9ACF

#card-content
	p
		font-size: 13px
		color: #333
		margin: 0
	span
		font-size: 18px
		line-height: 20px
		font-weight: 500

.summary-card-skeleton
	width: 175px
	height: 62px
	padding: 10px
	.ant-skeleton-content .ant-skeleton-paragraph > li
		height: 12px
	