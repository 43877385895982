#currency-config
	width: 40em

	.ant-popover-inner-content > .ant-row
		&:first-child
			margin-bottom: 20px

		&:not(:first-child)
			margin-bottom: 10px

		&:last-child
			margin-top: 20px
			justify-content: center

		> .ant-row:last-child
			width: 100%

		.ant-select
			.ant-select-selector
				border-radius: 9px
				background: #F2F2F2
				border: none

			.ant-select-arrow
				font-size: 22pt
				color: #4F9ACF