.filter-header-container
	width: 96%
	display: flex
	align-items: center
	justify-content: space-between
	margin: 24px 24px 0
	padding-bottom: 20px
	.ant-carousel
		width: calc(96% - 240px)
	.open-filter-button
		border-right: 2px solid #ccc!important
	.gs-carousel
		.slick-arrow.slick-next
			z-index: 2 !important
		.slick-arrow.slick-prev
			z-index: 2 !important
	.ant-select-selector
		height: 51px !important
		align-items: center
	.gs-flex
		display: flex
		margin-left: 24px
	.gs-secondary-button
		height: 51px
