.ant-carousel
	width: calc( 100% - 25px )
.gs-carousel
	.slick-disabled
		display: none !important
	.slick-arrow.slick-next
		z-index: 1000
		top: 45%
	.slick-arrow.slick-prev
		z-index: 1000
		left: 0px
	.slick-arrow.slick-next, .slick-arrow.slick-prev
		background: #0065b3
		color: #fff
		padding: 3px
		border-radius: 50%
		width: 25px
		height: 25px
	.slick-slide:not([data-index="0"])
		padding-left: 24px

	.slick-slide > div
		display: flex
		justify-content: flex-start
