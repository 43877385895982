.color-picker-content
	.github-picker
		gap: 5px
		background: transparent !important
		border: none !important
		box-shadow: none !important
		justify-content: center
		[tabindex="0"]
			border-radius: 4px
			border: 1px solid #d6d6d6
	.default-colors
		margin-bottom: 9px
		display: flex
		justify-content: center
	.custom-colors-title
		span
			color: #5C5C5C
			padding: 0 13px
	.custom-colors-button
		margin: 8px 0
		display: flex
		align-items: center
		gap: 7px
		padding: 0 7px
.custom-color-content
	padding: 10px 5px
	.footer-popover
		display: flex
		justify-content: end
	.chrome-picker
		box-shadow: none !important
		&>div:first-of-type
			border-radius: 13px 13px 0 0 !important
			.saturation-white
				&>div:nth-of-type(2)
					div
						height: 20px !important
						width: 20px !important
						border: 1px solid #fff !important
						border-radius: 100% !important
		&>div:nth-of-type(2)
			padding: 0px 0px 12px !important
			input
				font-family: Open Sans
				margin-top: 18px
				&:focus-visible
					// opacity: 0.7
					// box-shadow: rgb(181,181,181) 0px 0px 0px 1px inset !important
			label
				margin: -27px 0px 0px 30px !important
				text-align: left !important
			&>div:first-of-type
				flex-direction: column-reverse
				gap: 15px
				&>div:first-of-type
					&>div:first-of-type
						width: 20px !important
						height: 20px !important
						border-radius: 0 !important
						&>div:first-of-type
							border-radius: 2px !important
				&>div:nth-of-type(2)
					&>div:first-of-type
						height: 18px !important
						.hue-horizontal
							border-radius: 0 0 14px 14px
							height: 20px !important
							&>div
								&>div
									height: 20px !important
									width: 20px !important
									border: 2px solid #fff
									border-radius: 100% !important
			&>div:nth-of-type(2)
				padding: 0 !important
				&>div:first-of-type
					&>div:first-of-type
						&>div:first-of-type
							display: flex
							flex-direction: column-reverse
							padding: 13px 0px
				&>div:nth-of-type(2)
					display: none
