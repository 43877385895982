.gs-table
	width: 100%
	.ant-table-thead th
		background: #44759A
		color: #fff
		padding: 5px
		&:hover
			background: #44759A

	.ant-table-summary
		tr
			td
				background: #F5f5f5
				padding: 8px
	.ant-table-tbody tr td
		padding: 5px
	.gs-table-input-editable-cell
		display: flex
		align-items: center
		justify-content: center
		position: relative
		.gs-table-replicate-buttons-container
			opacity: 0
			display: none
			transition: all 0.2s
			align-items: center
			justify-content: space-between
			position: absolute
			&.left
				left: 0
			&.right
				right: 0
			svg
				font-size: 18px
				color: #333
				cursor: pointer
				background: #fff
		&:hover
			.gs-table-replicate-buttons-container
				opacity: 1
				display: flex

.gs-table-secondary
	width: 100%
	.ant-table-thead th
		background: #F5F5F5
		&:hover
			background: #F5F5F5

	.ant-table-summary
		tr
			td
				background: #FBE6E6
