.budget-collaborators-item-security

	.tabs-sider

		.ant-tabs-nav
			margin: 0

		.ant-tabs-nav-operations
			display: none

		.ant-tabs-top
			margin: 0

		.ant-tabs-nav-list
			width: 100%

			.ant-tabs-tab
				justify-content: center
				width: 50%
				margin: 0 !important
				border-bottom: none

			.ant-tabs-tab-active

				.ant-tabs-tab-btn
					font-weight: 450
					color: #104B77

		.sider-content
			background: #FCFCFC
			padding: 12px
			border: 1px solid #E2E2E2
			height: 69%

			.ant-tree-list
				height: 100%
				.ant-tree-list-holder
					height: 100%
					overflow-y: scroll
					.ant-tree-title
						overflow: hidden
						text-overflow: ellipsis
						width: 145px
						display: block

			.ant-input-affix-wrapper
				border: none

			.ant-tree
				margin-top: 15px
				height: calc( 100% - 50px )

				.ant-tree-list-holder-inner
					background: #FCFCFC
					height: 100%
					overflow-y: auto
					overflow-x: hidden

					.ant-tree-treenode-switcher-close
						height: 100%
						width: 97%

						.ant-tree-switcher-noop,
						.ant-tree-indent
							display: none

						span
							width: 100%

			.ant-input
				height: 32px
	.tabs-table

		.ant-tabs-tabpane
			border-left: 1px solid #D8D8D8
			border-right: 1px solid #D8D8D8

		.title-colunm-checkbox
			display: flex
			align-items: center
			justify-content: start
			gap: 10px
		.ant-tabs-tab
			width: 20% !important

	.page-content
		padding: 16px
		height: calc( 100vh - 120px )
		position: relative

		.ant-col-5
			height: 100%

			.ant-tabs
				height: 100%
				.ant-tabs-content
					height: 100%

			.ant-tabs-tabpane
				height: 100%
				.sider-content
					height: 100%

		.ant-col-19
			.ant-tabs
				height: 100%
				.ant-tabs-content-holder
					height: 100%
					.ant-tabs-content
						height: 100%
						.ant-tabs-tabpane
							height: 100%
							position: relative
							.search-container
								display: flex
								justify-content: flex-end
							.ant-input-affix-wrapper
								margin: 10px 0
								width: 270px
							.ant-form
								height: 100%
								.ant-table-wrapper
									height: calc( 100% - 47px )

	.footer-buttons
		position: absolute
		bottom: 0
		right: 0
		width: 100%
		display: flex
		justify-content: flex-end
		box-sizing: border-box
		padding: 16.8px 25px
		background: #fff
		border-top: solid 1px #D8D8D8
		border-bottom: 1px solid #d8d8d8
