#wfn-chart-icon
  width: 14px
  height: 14px

  &-main-button
    width: 14px
    height: 14px

    > i
      font-size: 14px
      color: #5C5C5C

#modal-environment-dialog
  margin: 1rem
  min-height: 160px !important
  .environment-modal-header
    display: flex
    justify-content: space-between
    h3
      font-weight: 400
      font-size: 18px
      line-height: 22.5px
      color: #424242
    button
      margin: 0
      padding: 0
      border: none
      background: none
      color: inherit
      font: inherit
      cursor: pointer
      outline: none
      :hover
        background-color: rgb(170, 170, 170, 0.1)
        border-radius: 8px

      svg
        color: #717171
        width: 24px
        height: 24px

  .divider
    margin: 10px 0
    border: none
    border-top: 1px solid #ddd
    margin-bottom: 31px

.btn-wrapper
  display: flex
  flex-direction: column
  gap: 24px

.change-environment-modal-btn
  display: flex
  align-items: center
  cursor: pointer
  background-color: #FCFCFC
  width: 41rem
  height: 4rem

  &:hover
    background-color: #f8f8f8

.btn-text
  font-weight: 600
  font-size: 18px
  line-height: 24.51px
  color: #424242

.btn-label
  cursor: pointer
  border: solid 0.5px #D7D7D7
  border-radius: 0 5px 5px 0
  border-left: none
  width: 100%
  padding: 20px

.icon-wrapper
  display: flex
  align-content: center
  justify-content: center
  align-items: center
  width: 89px
  height: 67px
  border: solid 0.5px #D7D7D7
  border-radius: 5px 0 0 5px

#wfn-modal-icon-main-button > i
  color: #0065B3
  font-size: 24px
