.filter-popover
	max-height: 30rem !important
	max-width: 40em

	.ant-popover-content
		padding: 3px 16px

.content-filters

	display: flex
	flex-direction: column
	align-items: center
	gap: 0.7rem 0
	margin-bottom: 10px

	.filter-header-content
		display: flex
		flex-direction: column
		gap: 0.5rem 0
		width: 100%

		> div
			display: flex
			justify-content: space-between
			flex-direction: row

	.filter-content
		width: 100%

		.anticon-loading
			font-size: 40px
			padding: 100px 0px
			display: flex
			flex-direction: row
			flex-wrap: nowrap
			justify-content: center

		> .ant-checkbox-wrapper
			width: -webkit-fill-available
			padding: 0.3rem
			margin-top: 24px
			background: #FBFBFB

		.ant-checkbox-group
			max-height: 19rem
			overflow-y: auto
			display: flex
			flex-direction: column
			width: -webkit-fill-available
			margin: 3px 0 0 0

		.ant-input-group
			display: flex

			.ant-input
				border-right: none

			.ant-btn
				border-top-left-radius: 0
				border-bottom-left-radius: 0

	.filter-content::-webkit-scrollbar
		-webkit-appearance: none
