.ant-select.gs-lookup
	width: 100%

	.ant-select-selector
		border-color: #4F9ACF

		.ant-select-selection-item

			img
				width: 25px
				margin-top: -3px
				margin-right: 5px

	.ant-select-arrow

		.ant-divider
			top: 0
			margin: 0
			height: 32px
			border-color: #4F9ACF

			.ant-divider-inner-text
				color: #4F9ACF
				padding: 7px 0 0 7px

.ant-select-dropdown

	.ant-select-item-option-content > img
		width: 25px
		margin-top: -3px
		margin-right: 5px
