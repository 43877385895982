.screen-filter-modal

	.screen-filter-modal-header
		display: flex
		padding: 1rem 0
		font-size: 1.3rem
		border-bottom: 1px solid #D9D9D9
		padding: 1rem 0 0 1rem

	.ant-modal-body
		padding: 24px 24px 10px

	.screen-filter-modal-content
		height: 100%
		display: flex
		flex-direction: row
		justify-content: space-between
		margin: 1rem 1rem 0

	.sceneray-title
		padding: 5px 12px
		display: flex
		align-items: center
		background: rgba(95, 162, 209, 0.39)
		border-radius: 3px

		p
			color: #104B77
			margin: 0
			font-weight: bold

	.sceneray-content
		max-width: 100%
		background: #FCFCFC
		border: 1px solid #D7D7D7
		border-radius: 3px
		padding: 5px 5px
		min-height: 350px

		.ant-tree
			background: #FCFCFC

			.ant-tree-list-holder-inner
				padding: 8px 0 3px 0px

				.ant-tree-node-content-wrapper
					transition: none
					color: #717171

				.ant-tree-switcher
					display: none

				>.ant-tree-treenode-selected
					width: -webkit-fill-available
					padding: 0 12px 3px 0px

					>.ant-tree-node-content-wrapper
						width: 100%

					>.ant-tree-node-selected
						background: #F5F5F5
						border: 1px solid #D7D7D7

	.scenery-filters-main
		display: flex
		flex-direction: column
		gap: 1rem
		width: 30%

	.screen-filter-modal-footer
		padding: 15px 0
		float: right
		margin-right: 1rem

		button
			display: inline-flex
			justify-content: center
			align-content: center
			margin-left: 1rem
			border: none
			cursor: pointer

			p
				margin: 0

		.button-cancel
			background: none
			padding: 3px

		.gs-main-button
			padding: 0px 30px
