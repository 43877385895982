@import '../variable'

.gs-date-content
	width: auto
	height: 30px
	display: flex
	border-radius: 15px
	flex-direction: row
	flex-wrap: nowrap
	align-items: center
	gap: 7px
	background-color: #EAF3F9
	padding: 5px 10px

	&.disabled
		background: #f5f5f5
		cursor: not-allowed

		svg,
		input
			cursor: inherit
			color: rgba(0, 0, 0, 0.25)

	&:not(.disabled)
		.gs-date-icon
			color: #4F9ACF

		.gs-date-separator
			color: #424242

		input
			cursor: default
			color: #424242

			&:focus
				border-bottom: 2px solid #1890ff

	.gs-date-icon
		font-size: 18px

	.gs-date-separator
		font-size: 7px

	input
		padding: 0
		border: none
		outline: none
		background-color: inherit
		font-size: 14px
		font-weight: bold

.gs-date-overlay-container
	padding-top: 0

	.ant-popover-content
		padding: 0

		.ant-popover-inner
			border: none !important
			border-radius: 0

.gs-date-picker-container
	margin: -12px -16px

	.ant-picker
		position: absolute
		visibility: hidden
		width: 0
		height: 0
		padding: 0

	>div:not(.ant-picker),
	.ant-picker-dropdown
		position: unset !important

.ant-picker-panel,
.ant-picker-panel-container

	.ant-picker-cell

		&:before
			height: $font-height

		.ant-picker-cell-inner
			width: 2.3rem
			height: 2.3rem
			font-weight: bold
			display: inline-flex
			align-items: center
			justify-content: space-around
			border-radius: 100% !important

			&:before
				border: none !important

	.ant-picker-cell-range-end, .ant-picker-cell-selected, .ant-picker-cell-range-start, .ant-picker-cell-range-hover-start
		height: $font-height

		.ant-picker-cell-inner
			background: $font-blue-color

		.ant-picker-cell
			height: $font-height

	.ant-picker-footer
		border: none
		display: flex
		flex-direction: column-reverse

		.ant-picker-footer-extra
			padding: 0.8rem
			display: flex
			justify-content: center
			flex-direction: column
			align-items: center
			row-gap: 10px

.ant-picker-suffix
	font-size: calc($font-height / 2)

.ant-picker-input
	display: flex
	flex-direction: row-reverse
	gap: 0.5rem

.ant-picker
	width: calc($font-height * 5)
	background: $background
	border-radius: 2px
	border: $border
	box-shadow: $box-shadow

	input
		font-weight: 650
		width: 6em

		&::-webkit-input-placeholder
			font-weight: lighter
			color: rgba(0, 0, 0, 0.85)

	.ant-picker-suffix
		color: #44759A

.ant-picker-clear
	background: #e9f3f9

.ant-picker-cell-in-view
	color: #106279
	font-weight: bold !important

.ant-picker-footer
	border: none

	.ant-picker-footer-extra
		padding: 0.8rem
		display: flex
		justify-content: center

.ant-picker-content

	tr
		height: $font-height
		padding: 0

	.ant-picker-cell
		height: $font-height
		padding: 0

	.ant-picker-cell:hover

		.ant-picker-cell-inner
			width: $font-height
			height: $font-height
			display: inline-flex
			align-items: center
			justify-content: space-around
			padding: 0 !important

.ant-picker-cell-range-hover::after
	height: $font-height !important

.ant-picker-cell-range-hover-start::after
	height: $font-height !important
	border-radius: $font-height 0 0 $font-height!important

.ant-picker-cell-range-hover-end::after
	height: $font-height !important
	border-radius: 0 $font-height $font-height 0 !important

.ant-picker-range
	width: auto

	.ant-picker-input
		min-width: min-content

	.ant-picker-suffix
		order: -1
		margin-right: 0.5rem

.button-filter-footer
	background: $font-blue-color
	width: 8rem
	color: $light
	font-weight: 450

	&:hover
		background: $font-blue-color
		filter: contrast(0.8)
		color: $light

// criado após analytics

.gs-date-picker
	border: 1px solid #ccc
	border-radius: 3px
	background: #fff
	width: 100%
	input
		font-weight: 400
	.ant-picker-input
		flex-direction: row
	.ant-picker-suffix
		order: unset
		margin-right: 0px
		color: rgba(0, 0, 0, 0.25)
		font-size: 15px
