.ant-tag
	margin: 0
	padding: 3px 7px
	border-radius: 5px

	&.white
		background: $light

	&.ant-tag-blue
		font-weight: bold
		color: $font-blue-color
		border: none
		background: rgba(89, 159, 209, 0.13)

.ant-table-cell

	.ant-tag
		width: 100%
		padding: 5px
		margin: 0
		text-align: center
		font-size: 10pt

		svg
			margin-right: 5px
			margin-bottom: -2px

.gs-tag
	font-weight: 500
	border-radius: 3px
	padding: 0 8px
	&.blue
		background-color: #E8F2F9
		color: #0065b3
	&.green
		background-color: #E6F6EC
		color: #2EB668
	&.gray
		background-color: #F1F2F3
		color: #5C5C5C
	&.red
		background-color: #FACCCC
		color: #920000
	&.yellow
		background-color: #f8efdc
		color: #b48527
	&.gray
		background-color: #F1F2F3
		color: #5C5C5C
	&.currency
		font-size: 10px
		background: #BCC5C9
		padding: 1px 8px
		border-radius: 20px

span.additional-items
	margin-left: 8px
	background: #e9f2f8
	padding-top: 1px
	border-radius: 5px
	font-size: 10px
	color: #2d7fc1
	font-weight: 600
	text-align: center
	padding: 1px 3px
