.edit-contract-modal
  form
    width: 100%

    .input-content
      margin-right: 0
      margin-bottom: 5px
      width: 100%

    .input-indexer
      width: 39.125rem

    .ant-input-number
      width: 100%
      height: 30px
      padding: 0 11px

    .ant-form-item
      display: block !important

    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before
      display: none

    .ant-form-item-label > label
      height: auto !important
      color: #5C5C5C
      font-size: 12px
      line-height: 16px

    .ant-picker
      border-radius: 2px
      width: 100%
      border: 1px solid #d9d9d9
      background-color: white
      input
        font-weight: 400

  .ant-table-title
    display: flex
    justify-content: center
    align-items: center
    height: 25px
    background-color: #0D2F48
    font-weight: 600
    font-size: 12px
    line-height: 16px
    color: #FFFFFF

  .edit-info
    width: 100%
    background-color: #F5F5F5
    display: flex
    flex-direction: row
    padding: 16px
    gap: 4.6875rem
    margin-top: 1.75rem
    margin-bottom: 1rem

  .edit-info-group
    display: flex
    justify-content: space-between
    flex-direction: column
    margin-bottom: 8px
    font-style: normal
    font-weight: 600
    font-size: 10px
    line-height: 125%

  .title
    color: #072D49

  .value
    color: #7A7A7A

  .value-disabled-opacity
    opacity: 60%