.hcm-tooltip-base 
  width: 300px
  min-height: 180px
  background-color: transparent
  box-sizing: content-box
  position: absolute
  z-index: 9999999
  -webkit-transition: all 0.3s ease
  -moz-transition: all 0.3s ease
  -ms-transition: all 0.3s ease
  -o-transition: all 0.3s ease
  transition: all 0.3s ease
  user-select: none

.coachmark-container 
  display: flex
  display: flex-box
  flex-direction: column
  justify-content: space-between
  width: 300px
  min-height: 180px
  background-color: #f5f5f5
  border: 1px solid #D7D7D7
  border-radius: 3px
  z-index: 100003
  overflow: hidden

.coachmark-title-container 
  display: flex
  align-items: flex-start
  justify-content: space-between
  width: auto
  margin: 15px

.coachmark-title 
  font-size: 18px
  color: #104b77
  margin: 0

.coachmark-description-container 
  width: auto
  margin: 0 15px 15px
  font-size: 14px
  line-height: 19px
  color: #717171

.coachmark-buttons-container 
  display: flex
  display: flex-box
  align-items: center
  justify-content: flex-end
  justify-content: flex-end
  color: #104b77
  background: #fbfbfb
  padding: 8px 10px
  margin: 0

.coachmark-button-skip 
  height: 100%
  width: auto
  text-align: center
  border-radius: 3px
  background-color: #fbfbfb
  cursor: pointer
  color: #104B77

.coachmark-button-primary 
  background: #FBFBFB
  border-radius: 3px
  color: #104B77
  cursor: pointer
  padding: 3px 8px
  border: 1.5px solid #104B77
  transition: background 0.3s

.coachmark-button-primary:hover 
  background: rgba(0,0,0,0.04)

.coachmark-button-skip 
  margin-right: 10px
  border: none
  opacity: 1

.dot 
  height: 6px
  width: 6px
  margin-right: 5px
  background: #bbb
  border-radius: 50%
  display: inline-block

.current-dot 
  background: #4F9ACF

.focus-spot 
  position: absolute
  height: 30px
  width: 30px
  background: #fff
  border: 1px solid #104B77
  border-radius: 50%
  z-index: 10002
  -webkit-transition: all 0.3s ease
  -moz-transition: all 0.3s ease
  -ms-transition: all 0.3s ease
  -o-transition: all 0.3s ease
  transition: all 0.3s ease

.focus-spot:before 
  position: absolute
  content: " "
  z-index: -1
  top: 5px
  left: 5px
  right: 5px
  bottom: 5px
  border: 1px solid #104B77
  border-radius: 50%

.coachmark-overlay 
  position: fixed
  top: 0
  z-index: 10000
  opacity: 0.4
  background-color: #000
  width: 100%
  height: 100%
  top: 0
  left: 0
  right: 0
  bottom: 0

.cm-step1
  background: #fff