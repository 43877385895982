.rates-btn-order
	order: -1 !important
	border: 1px solid #cccccc !important

.consolidation-elimination-header
	display: flex
	width: 100%
	justify-content: space-between
	min-height: 100px
	align-items: end
	.open-filter-button
		height: auto
		border: none
		max-width: max-content
		padding: 30px
		cursor: auto

.consolidation-elimination-main
	background: #f1f2f3
	padding: 16px
	.steps-consolidation
		border-radius: 9px
		background: #fff
		.consolidation-elimination-table
			padding: 20px 20px 0
	.consolidation-table-header
		padding: 10px 5px
		background-color: #fff
		display: flex
		justify-content: space-between
		.consolidation-search-container
			display: flex
			gap: 5px
			align-items: center
			padding-right: 5

.edited-value 
	background-color: rgba(46, 182, 104, 0.12) !important