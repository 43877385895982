.ant-layout

	&:not(.ant-layout-has-sider):not(& > .ant-layout)
		padding: 10px

	.ant-layout-header
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		align-items: center
		margin-bottom: 10px
		border-radius: 5px

	.ant-layout-sider:not(.ant-layout-sider-collapsed)
		margin-right: 10px
		border-radius: 5px

	&.light
		.ant-layout-header,
		.ant-layout-sider
			background: $light

.page-title-content
	display: flex
	align-items: center
	justify-content: flex-start
	background: #fff
	padding: 5px 24px 0
	width: 100%
	h1
		font-weight: 600
		font-size: 18px
		margin: 0 0 4px 0