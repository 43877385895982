.gs-top-buttons
	display: flex
	width: 100%
	align-items: center
	justify-content: space-between
	background: #fff
	.top-tool-buttons
		margin-left: 5px
		border: none
		box-shadow: none
		&:disabled
			background: #fff
		svg
			font-size: 22px
		.ant-btn:focus
			color: rgba(0, 0, 0, 0.85)
		button
			border: none
			box-shadow: none
			&:disabled
				background: #fff

	.edit,
	span.trash,
	button.trash
		transition: all 0s
		&:focus
			color: rgba(0, 0, 0, 0.85)

	.edit:not(:disabled):hover,
	button.trash:hover
		svg
			color: rgba(0, 0, 0, 0.85)

	.top-button
		border: none
		background: #fff

	.deactivate
		margin-left: -5px
		width: 50px
		padding-bottom: 30px
		border: none
		box-shadow: none
		.ant-picker
			background: #FFFFFF
			cursor: default
			border: none
			box-shadow: none
			input
				display: none
	span.deactivate,
	button.deactivate
		transition: all 0s
		&:focus
			color: rgba(0, 0, 0, 0.85)
	.deactivate:not(:disabled):hover
		color: rgba(0, 0, 0, 0.85)

.top-buttons-left-content
	display: flex
	align-items: center
	justify-content: flex-start
	margin: 15px 24px

.top-buttons-right-content
	display: flex
	align-items: center
	justify-content: flex-end
	margin: 15px 24px

.popconfirm-delete
	.ant-popover-inner
		padding: 5px 15px
		button
			height: 32px
			border-radius: 5px

.main-dropdown
	width: 40px
	border-radius: 0 5px 5px 0 !important
	background-color: #004D7B

	svg
		margin: 0 !important
		width: 12px

	&:focus
		background-color: #004D7B !important

.main-button-dropdown
	border-radius: 5px 0 0 5px !important
.filter-selected-content
	border: 1px solid #D7D7D7
	margin: 0 24px
	border-radius: 7px
	padding: 10px 10px
	gap: 10px
	button
		color: #004D7B
	.flex-field-selected
		display: flex
		align-items: center
		justify-content: center
	p
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
		margin: 0
