.loading-container
	display: flex
	flex: 1
	height: calc(100vh - 200px)
	flex-direction: column
	align-items: center
	justify-content: center
	margin: 10px auto -10px
	p
		color: #1890ff

.gs-spin
	background: #fff
