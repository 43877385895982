#top-buttons-flex-field
	padding-right: 24px
	> div
		width: calc(100% - 20px)
		display: inline-block

.flex-field-value
	.sider-content
		display: flex
		justify-content: flex-end

		.ant-menu-vertical
			border: none

	.side-flex-field-list-container
		height: 81vh
		border: 1px solid #ccc
		padding: 16px
		border-radius: 4px
		width: 100%
		margin: 16px 0 16px 16px
		div
			&:last-child
				overflow-y: hidden
		.ant-menu-root
			height: 100%
			overflow-y: auto
			padding: 0 7px 0px 0
.flex-field-modal

	.flex-field-values-form
		display: flex
		flex-direction: column
		align-items: center
		width: 100%

		.ant-row
			width: 100%
		.gs-secondary-button
			align-self: end
		.ant-picker
			background: #fff
			border: 1px solid #ccc
			border-radius: 4px
			width: 100%
		.flex-field-line
			p
				margin: 0

	.flex-field-table
		.ant-table-cell
			padding: 6px

	.flex-field-list-content
		.ant-row
			padding-left: 10px
			height: auto !important
			max-height: 52px !important
			min-height: 32px
			.ant-col.ant-col-14
				display: -webkit-box
				-webkit-box-orient: vertical
				-webkit-line-clamp: 2
				overflow: hidden
				max-height: 50px
				padding: 5px 0
