
.parameterization-from-to-page
    font-family: 'Open Sans', sans-serif
    background: #fff
    padding: 16px

    .title-page
        display: flex
        align-items: center
        justify-content: flex-start
        h1
            margin: 10px 0 11px 24px
            color: #424242
            font-family: Open Sans
            font-size: 18px
            font-style: normal
            font-weight: 600
            line-height: 125%
        label
            display: flex
            align-items: center
            justify-content: center
            margin-left: 16px
            width: 22px
            height: 22px
            border-radius: 6px
            border: 1px solid #CACACA

    .top-buttons-right-content
        width: 635px
        margin: 15px 100%

    .subtitle-page
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%

    select
        width: 337px
        height: 40px
        border-radius: 2px

    .gs-table
        width: 98%
        margin-left: 23px

        .ant-table-thead
            th
                border: 0.5px solid #dadada
            td
                border: 0.5px solid #dadada

.modal-new-parameterization
    width: 832px
    height: 526px !important

    .div-first-field
        display: flex
        gap: 16px

    .flexible-field
        border: none

    .select-flexible
        margin-top: 10px
        background: #fff
        border: none

    #second-field
        display: flex
        gap: 16px

        .input
            width: 373px

    .div-add-table
        margin-right: 21px
        margin-bottom: 16px
        display: flex
        justify-content: flex-end

    .gs-table
        width: 762px

        .ant-table-thead
            th
                border: 0.5px solid #dadada
            td
                border: 0.5px solid #dadada
