#contract-simulation
  display: flex
  align-itens: center
  align-content: center
  flex-direction: row
  justify-content: space-between
  align-items: center
  .contract-simulation-title
    margin: 0 1.5rem
  .tag-wrapper
    display: flex
    gap: 8px
    flex-wrap: wrap
    justify-content: flex-end

#contract-main
  margin: 0 1.5rem

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table
    border-top: none

  .loading-wrapper
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 9999
    width: 100%
    height: 100%
    backdrop-filter: blur(1px)

  .grid-info
    display: flex
    justify-content: space-between

    .grid-length
      margin: 0
      font-size: 12px
      font-weight: 400
      line-height: 16px
      color: #0065B3
      align-self: center
