.gs-modal-filter
	.ant-modal-body
		padding: 0
		.ant-layout-sider-children,.ant-layout-header,.ant-layout
			background: #fff
		&>.ant-layout
			padding: 0
			gap: 20px
		.ant-layout-header
			padding: 0
			border-radius: 0
			.ant-row
				padding: 0 10px
			.ant-picker-suffix
				order: -1
				svg
					color: #5c5c5c
		.filter-content
			flex-direction: row
			border-top: 1px solid #d7d7d7
			padding-top: 15px
			main
				padding: 0 0 0 18px
				border-left: 1px solid #D7D7D7
				.values-table-content
					display: flex
					flex-direction: column
					justify-content: end
					&>span
						align-self: end
						width: 30%
			aside
				padding: 0 5px
				background: #fff
				max-height: 300px
				overflow-y: auto
				button
					height: auto
					width: 100%
					padding: 0
	.selected-card
		background: #f5f5f5
	.option-filter-card
		border: 1px solid #D7D7D7
		border-radius: 5px
		align-items: center
		justify-content: space-between
		height: 52px
		width: 100%
		padding: 0 10px
		p
			color: #0065b3
			margin: 0
			font-weight: 700
	.ant-modal-footer
		display: flex
		justify-content: end
