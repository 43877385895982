.contract-counter
  display: flex
  height: 1.5626rem
  color: #FFFFFF

  .counter-label
    padding: 5px 8px
    background-color: #234660
    font-size: 12px
    font-weight: 600
    line-height: 12px
    letter-spacing: 0em
    text-align: left

  .counter
    background-color: #0D2F48
    min-width: 1.5626rem
    padding-left: 5px
    font-size: 13px
    font-weight: 700
    line-height: 18px
    letter-spacing: 0em
    text-align: left
    vertical-align: middle
    display: flex
    align-items: center
    justify-content: center

  .ant-btn-circle
    min-width: 1.5626rem

  .refresh-button
    height: 1.5626rem
    padding: 0
    background-color: #0D2F48
    color: #FFFFFF
    border: none
    border-radius: 0
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    position: relative

    &:hover
      background-color: #234660

  .refresh-icon
    display: flex

  .rotate-icon
    animation: spin 0.8s infinite

  @keyframes spin
    100%
      transform: rotate(360deg)
