#btn-user-info
	width: auto
	height: 100%
	margin-left: 15px
	border: none
	border-radius: 0
	background: #EAEAEA
	cursor: pointer
	padding-left: 15px

.btn-user-content 
	display: flex
	align-items: center
	justify-content: space-between
	width: 100%

	svg
		font-size: 16px
		color: #7a7a7a 
		margin-top: 8px

	h5
		color: #0065B3
		margin-bottom: 0

.user-container
	background: #fff
	min-width: 250px

.user-info-container
	width: 100%
	background: #0B2538
	color: #fff
	padding: 20px 20px 10px

	h3
		font-size: 16px
		font-weight: bold
		color: #fff

	.button-change-scenery
		background: transparent
		border: none
		color: #fff
		display: flex
		justify-content: flex-end
		width: 100%

.user-freemium
	background: #44759A !important

.user-info-content
	display: flex
	align-items: flex-start
	justify-content: flex-start

	svg
		margin: 5px 10px 0 0

	div
		display: flex
		flex-direction: column
		align-items: flex-start
		justify-content: center

	p
		margin: 0

.user-menu-freemium
	background-color: #045594 !important
	li
		color: white

.user-nav-content.ant-menu
	background: #0d2f48
	padding: 8px 0
	border-right: none

	.ant-menu-item
		display: flex
		align-items: center
		margin: 0
		height: 34px

		&:hover
			background: #134a72

		&.ant-menu-item-selected:not(:hover)
			background: none

	svg
		color: #fff

	.ant-menu-title-content
		a,
		div
			color: #fff

			&:hover 
				color: #fff
