.projection-groupres-page
	background: #fff
	.top-buttons-left-content
		margin: 0 15px
	.ant-layout-header
		padding: 0
		height: auto
		.ant-row
			width: 100%
	.ant-layout-content
		padding: 0 15px
	.trash
		display: flex !important
.projection-grouper-modal
	.list-of-added-items
		border-top: none
		.ant-row
			margin-left: 0 !important
			margin-right: 0 !important
			padding: 0 12px !important
			.ant-col
				justify-content: flex-start !important
				text-overflow: ellipsis
				overflow: hidden
				display: block !important
				white-space: nowrap
