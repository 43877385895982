.title-hr-collaborators
	display: flex
	justify-content: space-between
	margin-bottom: 15px
	.page-title-content
		margin: 0
		padding: 0
.collaborator-container
	background: #fff
	padding: 10px 30px
	h3
		font-size: 18px
		font-weight: normal
		margin-bottom: 5px
.human-resources-table
	.blocked-cell
		color: #2eb668
		background: rgba(46,182,104,.12)
		opacity: 0.8
	.first-column
		color: rgba(0, 0, 0, 0.85)
	.summary-total
		td
			padding: 3px 0 3px 5px
.collaborator-header
	display: flex
	align-items: center
	justify-content: space-between
	width: 100%
	.collaborator-header-left-content
		display: flex
		flex-direction: column
		align-items: start
		justify-content: center
	.collaborator-header-right-content
		display: flex
		align-items: center
		justify-content: space-between

.calculate-dropdown-container
	display: flex

.human-resources-dropdown
	display: flex
	align-items: center
	justify-content: center
	width: 32px
	height: 35px
	border-radius: 0 5px 5px 0 !important
	
	svg
		margin: 0 !important
		width: 18px

.collaborator-header-right-buttons
	display: flex
	gap: 5px
	align-items: center
	font-weight: 500
	color: #0065B3
	height: 35px
	svg
		font-size: 18px
		color: #424242
.collaborator-tables-container
	flex-direction: column
	display: flex
	align-items: center
	.collaborator-tables-right-buttons
		display: flex
		gap: 12px
		align-self: end
		margin-bottom: -35px

	.collaborator-input-budget-tables
		display: flex
		align-items: start
		justify-content: space-between
		width: 100%
		.ant-tabs-tab-active > div
			font-weight: 500
		.ant-tabs
			width: 100%
			.ant-tabs-nav
				width: 70%
				margin: 0

	table
		th
			padding: 8px !important
		td
			padding: 4px
		input
			max-width: 60px

.collaborator-result-budget-tables
	position: relative
	display: flex
	align-items: start
	justify-content: space-between
	width: 100%
	margin-top: 50px
	.is-result-values
		.ant-table-container
			overflow-x: auto
	.ant-tabs-nav-list
		width: 40%
	.ant-tabs-tab-active > div
		font-weight: 500
	.ant-tabs
		width: 100%
		.ant-tabs-nav
			margin: 0
	table
		th
			padding: 8px !important
		td
			padding: 4px
	.result-export-button
		position: absolute
		right: 0
		top: 10px
