.all-items-checked-info
    padding: 10px
    background-color: #F3F5F5
    margin-bottom: 10px
    border-radius: 5px
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    gap: 10px
    p
        margin-bottom: 0

    button
        color: #478BBB
        font-weight: 500
        cursor: pointer
        border: none