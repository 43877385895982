.average-cost-modal
  .import-button
    height: 34px
    display: flex
    align-items: center
    justify-content: center
    gap: 8px
    padding: 8px 16px
    border: 1px solid #0D2F48
    color: #0D2F48
    background-color: transparent
    cursor: pointer
    transition: all 0.3s
    font-weight: 400
    font-size: 16px
    line-height: 21.79px

  .import-button:hover
    color: #fff
    background-color: #0D2F48

  .import-button:focus
    color: #fff
    background-color: #0D2F48
    border-color: #0D2F48

  .averagecost-buttons-wrapper
    display: flex
    justify-content: space-between
    margin-bottom: 1rem
    width: 100%