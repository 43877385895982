#top-buttons-professional-category
    padding-right: 24px
    > div
        width: calc(100%  -  20px)
        display: inline-block

#professional-category-main
    margin-left: 24px
    margin-right: 24px

.professional-category-modal
    form
        width: 100%
        .fields-container
            width: 100%
            display: flex
            justify-content: space-between
            gap: 10px

            .input-content
                margin-right: 0
                margin-bottom: 5px

            .input-content:nth-child(2)
                width: 440px
            .ant-form-item
                display: block !important

            .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before
                display: none

            .ant-form-item-label > label
                height: auto !important

            .ant-picker
                border-radius: 2px
                width: 100%
                border: 1px solid #d9d9d9
                background-color: white
                input
                    font-weight: 400

        .plus-button
            margin-left: auto

    .form-new-category
        padding-bottom: 25px
        margin-bottom: 25px
        border-bottom: 1px solid #D7D7D7
        .ant-btn:focus
            color: #40a9ff
            border-color: #40a9ff
            svg
                color: #40a9ff

    .new-category-table-head
        width: 100%
        padding: 0 10px
        margin-bottom: 10px
        .ant-col
            font-size: 14px
            font-weight: 500

    .new-category-table-body
        width: 100%
        .ant-row
            border: 1px solid #D7D7D7
            border-radius: 4px
            padding: 2px 10px
            margin-bottom: 6px
            background-color: #FCFCFC
            .ant-col-1.ant-col
                max-width: 25px
                display: flex
                align-items: center
                justify-content: flex-end
                margin-left: auto
            .ant-col-1.ant-col:last-child
                margin-left: 0
            .edit-button
                font-size: 17px
                margin-top: 6px
            button
                height: 29px
                border: none
                padding: 0
                background: transparent
    .ant-btn
        box-shadow: 0 2px 0 rgb(0 0 0 / 0%)

    .category-list-empty
        margin: 60px auto 20px
        display: flex
        flex-direction: column
        align-items: center
        row-gap: 5px