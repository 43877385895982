.investment-section
    background: #f1f2f3
    .span-openings
        padding: 10px
        border-radius: 50%
        background-color: #E8F2F9
        color: #0065B3
        font-weight: 600
        font-size: 14px
        display: flex
        align-items: center
        justify-content: center
        width: 38px
        height: 38px
    .detail-table-date-picker
        background: #fff
.investment-modal
    .investment-second-stage
        height: 350px !important
        overflow-y: auto
        padding: 0 5px
        .grouping-line
            position: relative
            box-sizing: border-box
            padding-left: 10px
            &:before
                content: ''
                position: absolute
                width: 4px
                height: 100%
                border-radius: 5px
                background-color: #0065B3
                left: 0
                top: 0

    .modal-row-column
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        margin-left: 3px

    .new-account-table-head
        .ant-row
            .ant-col
                @extend .modal-row-column
    .new-account-table-body
        .ant-row
            .ant-col
                @extend .modal-row-column

    .clear-class-style-disabled
        color: initial
        background-color: initial

    .ant-input-number-disabled
        @extend .clear-class-style-disabled

    .ant-input-number-affix-wrapper-disabled
        @extend .clear-class-style-disabled

    .ant-btn-primary
        color: white
        &:disabled
            background: #F5F5F5
            border: 1px solid #DBDBDB
            color: #ADADAD
            &:hover
                background: #F5F5F5 !important
                border: 1px solid #DBDBDB !important
                color: #ADADAD !important
