.new-projection-page
    h1
        width: -webkit-fill-available

    .ant-steps-item-container
        display: flex
        align-items: center
        justify-content: center
        .ant-steps-item-icon
            display: flex
            align-items: center
            justify-content: center

    .site-navigation-steps
        .ant-steps-item-active
            .ant-steps-item-icon
                background: #0065B3
                border-color: #0065B3
            &::before
                background-color: #0065B3
    .button-steps
        display: flex
        justify-content: end
        margin: 15px 35px

    .projection-main
        padding: 0 24px
        display: block
        .conditions-title
            color: #0065B3
    .step-one-content
        .condition-operator
            align-self: center
            .button-active
                color: #096dd9
                border-color: #096dd9
        .conditions-form-content
            padding-left: 25px
        .fields-container-form1
            display: flex
            flex-wrap: wrap
            gap: 20px
            align-items: center
            border-right: 1px solid #d2d2d2d2
            padding-right: 25px
            form
                width: 100%
            .input-order
                width: 100%

        .fields-container
            .plus-button-container
                display: flex
                align-items: center
                justify-content: end

    .step-one-list-container
        background-color: #FCFCFC
        padding: 10px
        border: 1px solid #CACACA
        border-radius: 5px
        .step-one-list-line
            display: flex
            justify-content: center
            align-items: center
            border: 1px solid #D7D7D7
            border-radius: 3px
            background: #FCFCFC
            height: 34px
            width: fit-content
            padding-left: 16px
            box-sizing: border-box
            gap: 11px
        .step-one-list-label
            display: flex
            justify-content: center
            align-items: center
            padding: 1px 8px
            height: 18px
            background: #E8F2F9
            font-weight: 400
            color: #0065B3
            border-radius: 2px
    #step-two-new-projection
        height: 72vh
        display: flex
        &>div
            flex-grow: 1
            width: 50%
        .step-two-form-content
            height: 100%
            .form-container
                height: 100%
                .origin-container
                    padding-top: 2rem
                    height: 100%
                    border-right: 1px solid #d2d2d2d2
                    padding-right: 2rem
                    .button-origin
                        text-align: -webkit-center
                        padding: 0
                        .column-wrapper
                            padding: 16px
                .table-projection
                    margin-bottom: 1rem
        .formula-container
            padding-top: 2rem
            padding-left: 2rem
            display: flex
            flex-direction: column
            flex: 1
            .ant-input-number-input
                text-align: end !important
                padding: 0 4px !important
            .indexer-grouper
                flex-direction: column
    #step-three-projection
        .step-three-form-content
            .fields-projection
                display: flex
                flex-wrap: wrap
                .ant-form-item-with-help
                    width: 90%
                    .ant-col
                        display: flex
                    .ant-select-selector
                        height: 40px
                        align-items: center
            .sidebar-base
                width: 100%
                padding: 2rem
                border-left: 1px solid #d2d2d2d2
        .step-three-grid
            padding: 1rem
            background-color: #fcfcfc
            border: 1px solid #d7d7d7
            margin: 1.5rem 0 !important

.box-cronogram-container
    margin: 0 !important
    padding: 1rem 2rem 0 2rem
    background-color: #fcfcfc
    border: 1px solid #d7d7d7
    .slider-container
        display: flex
        align-items: center
        .slider-input-container
            paddingLeft: 2rem

.form-field-operations
    margin-left: 0
    margin-bottom: 1rem
    .operations-select-container
        label
            margin-bottom: 8px

.attribute_parameterization-new-attribute-modal-formula-workarea
    flex-flow: wrap

.projection-modeling-page
    padding: 0 !important

    .ant-layout-header
        margin: 0

    .top-buttons-projections
        display: block
        position: sticky
        top: 0
        z-index: 1
        padding: 0.5rem 0
        background: #fff

        .wrapper-top-buttons-projections
            .gs-top-buttons
                display: flex
                width: 100%
                align-items: center
                justify-content: space-between
                background: #fff
                position: sticky
                top: 0.5rem
                z-index: 1

                .top-buttons-left-content
                    margin: 0

            .top-buttons-projection-export
                display: flex
                align-items: center
                position: sticky
                top: .5rem
                z-index: 1
                float: inline-end
                margin-top: -2rem

        .sticky-none
            display: none

    .ant-layout-content
        .ant-table-content
            .ant-table-thead
                position: sticky
                top: 45px
                z-index: 2

.grouper-copy-form
    .fields-projection
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        .ant-col.ant-col-12
            flex: 0 0 calc(50% - 10px)