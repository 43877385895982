.open-float-menu-button
    position: absolute
    top: 84px
    z-index: 100
    right: 0
    display: flex
    align-items: center
    justify-content: center
    width: 34px
    height: 45px
    border-radius: 8px 0 0 8px
    border: none
    cursor: move
    background: #E8F2F9
    &:hover
        background: #e1eef7 
    svg
        color: #0B6BB6
        font-size: 34px
        &:hover 
            color: #2b7ab8

