#top-buttons-groupings
	padding-right: 24px

.budget-groupings
	height: 100%
	align-items: center
	.gs-top-buttons
		.top-buttons-left-content
			.customization-button
				border: none
	.page-content
		padding: 0 1rem 1rem
		width: 100%
		display: flex
		flex: 1
		.gs-tab
			flex: 1
			.ant-tabs-content-holder
				display: flex
				.ant-tabs-content
					flex: 1
		.ant-layout
			background: #fff

		.content-header
			margin: 0px 0 0 1%
			display: flex
			width: 99%
			align-items: center
			justify-content: space-between

			& > div

				p
					margin: 0
					color: #104B77
					font-weight: 600
					width: max-content

			.linked-search
				margin-right: 117px

			& > span
				width: 26%

		.row-tabs
			border: 1px solid #D7D7D7

		.unlinked
			color: #0065B3

		section
			height: 100%

			aside,
			main
				padding: 9px
				background: #FCFCFC
				border: 1px solid #D7D7D7

			main
				.title-unlinked-table
					display: flex
					justify-content: space-between
					align-items: center

					p
						margin: 0

					svg
						color: #0065B3
						font-size: 1rem

				.content-tables
					display: flex
					flex-direction: row
					justify-content: center
					gap: 1rem

					.all-items-checked-info
						min-height: 44px
						max-height: 60px

					& > div
						width: 50%
						display: flex
						flex-direction: column
						justify-content: space-between
						& > div.selected-all-table
							height: calc(100% - 120px) !important

						& > div
							height: calc(100% - 50px)

							.ant-spin-nested-loading,
							.ant-spin-container
								.ant-table
									height: calc(100% - 64px)
									overflow: auto

						.gs-main-button, .gs-secondary-button
							align-self: end
		.tree-level-warning-container
			display: flex
			flex-direction: column
			justify-content: center
			flex: 1

		.tree-level-container-new
			width: 100%
			.div-content
				display: contents
				flex: 1 1
				.search-div
					display: flex
					align-content: center
					align-items: center
					justify-content: space-between
					width: 50%
					.search-input-accounts
						margin-bottom: 5px
						width: 50%
			.div-content-table
				display: flex
				width: 100%
				justif-content: space-between!important
				gap: 1rem

	.ant-modal-body
		align-items: flex-start !important
		gap: 1rem 0

		.new-level-origin
			width: 45%

		.ant-form-horizontal
			display: flex
			flex-direction: column
			gap: 1rem 0
			width: 100%

			.new-level-inputs
				display: flex
				gap: 5%

				& > div

					&:first-child
						width: 45%

					&:last-child
						width: 50%

	.configure-levels-modal

		.ant-modal-body

			& > div
				width: 100%

	.site-tree-search-value
		color: #84a6c0

	.sider-content
		display: flex
		flex-direction: column
		gap: 0.2rem
		height: 100%
		width: 100%
		min-width: 250px

		.multiple-search-groupings
			width: 100%
			display: flex
			flex-direction: column
			.select-search-multiple-search
				width: 100%

		.tree-title-content
			display: flex
			justify-content: space-between
			font-weight: 500

			p
				margin: 0
				white-space: nowrap
				overflow: hidden
				text-overflow: ellipsis

			& > div
				display: flex
				align-items: center

				& > svg
					color: #0065B3

				button
					border: none
					background: transparent
					height: 24px
					align-items: center
					justify-content: center
					display: flex
					opacity: 0

					svg
						color: #0065B3
		.package-level-title
			color: #0065B3
		.tree-title-content:hover
			button
				opacity: 1

		.ant-input-affix-wrapper
			margin-bottom: 8px
			background: #E8F2F9
			border: 0
			border-radius: 4px

		svg
			font-size: 1rem !important

		.ant-tree-icon-hide
			height: 100%

			.ant-tree-list
				height: 100%

				.ant-tree-checkbox
					margin-top: 2px
					margin-left: 5px

				.ant-tree-list-holder
					height: 100%

					& > div
						overflow-x: hidden
						height: 100%

		.ant-tree-list-holder-inner
			height: 100%
			padding-bottom: 10px

			.ant-tree-treenode
				width: -webkit-fill-available

				.ant-tree-node-content-wrapper
					width: 100%
					min-width: 0

		.ant-tree-treenode,
		.ant-tree-list-holder-inner
			border: none

	.hierarchy-nomenclature-modal-container
		.ant-table-container
			.ant-form-item-control-input-content
				.ant-input:not(:focus)+span
					display: none

.cost-center-linked-package-modal
	.cost-center-list-container
		max-height: calc(100vh - 300px)
		min-height: 300px
		overflow: auto
		margin-top: .5rem
		.ant-checkbox-wrapper
			margin-top: 4px

.standard-account-package-modal
	.ant-modal-body
		min-height: 300px
		justify-content: start
	.ant-radio-wrapper span:not(.ant-radio):not(.ant-radio-inner)
		width: 100%
.captcha-group
	display: flex
	flex-direction: column
	align-items: center
	gap: 10px
	justify-content: center
	.ant-col-24
		width: 400px
		display: flex
		justify-content: center
		font-size: 17px
	.canvas-content
		width: 50% !important
	.input-content
		margin-left: auto
		margin-right: auto
