$background: #e9f3f9
$light: #fff

$border-radius: 1rem
$border: none
$box-shadow: none

$font-primary-color: #424242
$font-secondary-color: #7EB5DC
$font-green-color: #2ECC71
$font-green-semidark-color: #38a064
$font-red-color: #FF0C0C
$font-blue-color: #4F9ACF
$font-height: 2rem
$font-icon-color: #0065B3