.control-panel-content
    display: flex
    align-items: start
    width: 100%
    height: calc(100vh - 160px)
    .organization-list-tab
        width: 25%
        height: 100%
        padding: 0 0 16px 16px
        .ant-tabs-nav
            margin: 0
        .ant-tabs-content-holder
            padding-top: 16px
            border: 1px solid #d7d7d7
            border-top: none
            .ant-menu
                border: none
        .ant-tabs-tab:first-of-type
            width: 60%
        .organization-list-content
            height: calc(100vh - 282px)
            overflow: auto
    .organization-panel-container
        display: flex
        flex-direction: column
        align-items: start
        flex: 1
        margin: 0 16px 16px
        padding-bottom: 40px
        .organization-panel-header
            display: flex
            align-items: center
            justify-content: space-between
            width: 100%
            border: 1px solid #d7d7d7
            background: #fcfcfc
            padding: 8px 16px
            margin-bottom: 16px
            h3
                margin: 0 8px
        .organization-panel-year-box
            width: 100%
            background: #fcfcfc
            margin: 16px 0 0
            .ant-collapse-content
                border-top: none
            .ant-collapse-content-box
                padding: 0
                background: #fcfcfc
                form
                    padding: 16px 16px 0
                .ant-collapse
                    border: none
                    border-top: 1px solid #d9d9d9
                    background: #fcfcfc
                    .ant-collapse-content,
                    .ant-collapse-item
                        border: none
                        background: #fcfcfc
                    .date-placeholder
                        color: #424242ba
        .new-panel-year-box
            display: flex
            flex-direction: column
            align-items: start
            justify-content: start
            width: 100%
            border: 1px solid #d7d7d7
            background: #fcfcfc
            margin: 16px 0
            padding: 8px 16px
        .new-fiscal-year-select-container
            display: flex
            align-items: end
            border: 1px solid #d7d7d7
            background: #fcfcfc
            width: 100%
            margin: 16px 0 0
            padding: 16px
