.new-detail-modal
    .ant-modal-body
        margin-top: 25px

    .ant-modal-title
        font-size: 18px

    .detail-modal-header-container
        display: flex
        align-items: center
        justify-content: space-between

        .paragraph-filter-content
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
            font-size: 14px
            max-width: 300px !important
        .paragraph-filter-header
            color: #0065B3
            margin-bottom: 5px
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
            font-size: 14px
            font-weight: 500
            max-width: 300px !important

    .filters-flex-fields
        display: flex
        align-items: center
        margin-left: 15px
        width: 300px !important

    .filter-fixed
        width: 300px !important
        margin-right: 25px
        margin-left: 10px

    .filter-fixed-currency
        width: 300px!important

    .gs-top-buttons
        .top-buttons-left-content
            margin: 15px 0
    .gs-table
        min-height: 300px
        .ant-table-selection-col
            width: 30px
        .ant-table-thead th
            text-align: center
        tr:not(.add-detail-btn-row)
            td.frozen-column
                background: #BFE4FF
            td.realized-column
                background: rgba(46, 182, 104, 0.12)
                color: #2EB668
            td.revision-period-column
                background-color: #f8efdc
                color: #b48527
            td.out-of-period-column
                opacity: 0.8
        .ant-table-body
            .ant-table-cell
                height: 40px
        .gs-table-input-editable-cell
            cursor: pointer
            justify-content: center
    .editable-cell
        margin: 0
    .input-text-cell
        border: none
        width: 145px
    .text-cell
        display: block
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
        width: 140px

    .gs-secondary-button-disabled
        background: #f0f0f0!important
        color: #ADADAD!important
