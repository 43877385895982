#plan-from-to
	.page-title-content
		display: flex
		justify-content: space-between
		align-items: center
		.ant-picker
			background-color: #F6F6F6
			border-radius: 5px
			*
				color: #717171
			span
				display: flex
				align-items: center
				gap: 10px
	.page-content
		padding: 15px 24px
		.ant-tabs
			height: calc( 100vh - 192px )
			.ant-tabs-content
				height: 100%
			.ant-tabs-tabpane
				height: 100%
				.split
					height: 100%
					background: #fff
					.sider-content
						overflow: auto
						padding: 9px
						background: #FCFCFC
						border: 1px solid #D7D7D7
						p
							margin: 0
						svg
							color: #0065B3
							font-size: 1rem
	.content-container
		background: #fcfcfc
		border: 1px solid #d7d7d7
		padding: 9px
		min-width: 900px
		.tree-level-warning-container
			height: 100%
			.empty-list-container
				display: flex
				height: 100%

		.content-header
			margin: 0px 0 0 1%
			display: flex
			width: 99%
			align-items: center
			justify-content: space-between
			& > div
				p
					margin: 0
					color: #104B77
					font-weight: 600
					width: max-content
			.linked-search
				margin-right: 117px

			& > span
				width: 26%

		.title-unlinked-table
			display: flex
			justify-content: space-between
			align-items: center

			p
				margin: 0

			svg
				color: #0065B3
				font-size: 1rem

		.content-tables
			display: flex
			flex-direction: row
			justify-content: center
			gap: 1rem

			.all-items-checked-info
				min-height: 44px
				max-height: 60px

			& > div
				width: 50%
				display: flex
				flex-direction: column
				justify-content: space-between
				& > div.selected-all-table
					height: calc(100% - 120px) !important

				& > div
					height: calc(100% - 50px)

					.ant-spin-nested-loading,
					.ant-spin-container
						.ant-table
							height: calc(100% - 64px)
							overflow: auto

				.gs-main-button, .gs-secondary-button
					align-self: end

		.tree-level-warning-container
			display: flex
			flex-direction: column
			justify-content: center
			flex: 1

		.site-tree-search-value
			color: #84a6c0

	.sider-content
		display: flex
		flex-direction: column
		gap: 0.2rem
		height: 100%
		width: 100%

		.ant-checkbox-group
			display: flex
			flex-direction: column

		.tree-title-content
			display: flex
			justify-content: space-between
			p
				margin: 0
				white-space: nowrap
				overflow: hidden
				text-overflow: ellipsis

			& > div
				display: flex
				align-items: center

				& > svg
					color: #0065B3

				button
					border: none
					background: transparent
					height: 24px
					align-items: center
					justify-content: center
					display: flex
					opacity: 0

					svg
						color: #0065B3

		.tree-title-content:hover
			button
				opacity: 1

		.ant-input-affix-wrapper
			margin-bottom: 8px
			background: #E8F2F9
			border: 0
			border-radius: 4px

		svg
			font-size: 1rem !important

		.ant-tree-icon-hide
			height: 100%

			.ant-tree-list
				height: 100%

				.ant-tree-checkbox
					margin-top: 2px
					margin-left: 5px

				.ant-tree-list-holder
					height: 100%

					& > div
						overflow-x: hidden
						height: 100%

		.ant-tree-list-holder-inner
			height: 100%
			padding-bottom: 10px

			.ant-tree-treenode
				width: -webkit-fill-available

				.ant-tree-node-content-wrapper
					width: 100%
					min-width: 0

		.ant-tree-treenode,
		.ant-tree-list-holder-inner
			border: none
	.tree-level-container-new
		width: 100%
		.div-content
			display: flex
			.search-div-linked
				display: flex
				justify-content: end
				width: 50%
			.search-div
				display: flex
				align-content: center
				align-items: center
				justify-content: space-between
				width: 50%
			.search-input-accounts
				margin-bottom: 5px
				width: 50%
	.div-content-table
		display: flex
		width: 100%
		justify-content: space-between!important
		gap: 1rem

	.unlinked
		color: #0065B3
	.multiple-search-groupings
		width: 100%
		display: flex
		flex-direction: column
		.select-search-multiple-search
			width: 100%
