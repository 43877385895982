.label-container
    display: flex
    flex-direction: column
    align-items: start
    .label-title
        font-size: 14px
        color: #000000D9
        margin-bottom: 0
        font-weight: 500
    .label-description
        font-size: 14px
        color: #333333
        margin: 0
