.captcha-component-content
	.ant-popover-inner-content
		padding: 10px 16px
	.row-content
		flex-direction: column
		align-items: center
		gap: 15px
		&>.ant-row
			width: 100%
		.canvas-content
			background-color: #f0f0f0
			padding: 7px
			display: flex
			width: 100%
			justify-content: center
		.input-content
			display: flex
			button
				border-radius: 0
				border: none
				min-width: 35px
		.footer-content
			justify-content: end
.captcha-button.ant-popover-disabled-compatible-wrapper
	background-color: unset
	&:hover
		background-color: unset !important
