.transfer-container
    width: 100%

.transfer-title
    padding: 8px 16px 8px 16px
    background-color: #f1f2f3
    h3
        color: #045594
        margin: 0

.transfer-buttons
   display: flex
   flex-direction: column
   align-items: center
   justify-content: center
   .transfer-button
       margin: 8px

.filter-table
    width: 100%
    .ant-table-container
        height: 160px
    .ant-table-title
        padding: 8px
    .ant-table-thead th
        padding: 5px
    .ant-table-summary
        tr
            td
                background: #F5f5f5
                padding: 8px
    .ant-table-tbody tr td
        padding: 5px
    .gs-table-input-editable-cell
        display: flex
        align-items: center
        justify-content: center
        position: relative
        .gs-table-replicate-buttons-container
            opacity: 0
            display: none
            transition: all 0.2s
            align-items: center
            justify-content: space-between
            position: absolute
            &.left
                left: 0
            &.right
                right: 0
            svg
                font-size: 18px
                color: #333
                cursor: pointer
                background: #fff
        &:hover
            .gs-table-replicate-buttons-container
                opacity: 1
                display: flex

.transfer-footer
    width: 100%
    display: flex
    justify-content: flex-end