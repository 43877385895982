.analytics-setting-popover
    .ant-popover-content
        padding: 3px 16px
	
.content-settings
    display: flex
    flex-direction: column
    align-items: center
    gap: 1rem 0
    max-height: 30rem !important
    max-width:  40em
    margin-bottom: 10px

    .setting-content-header
        display: flex
        flex-direction: column
        gap: 0.5rem

    .ant-checkbox-group
        display: flex
        flex-direction: column
        gap: 0.5rem 0

    .ant-tree-switcher
        display: none !important

    .ant-tree-draggable-icon
        display: none !important

    .draggable-tree

        .ant-tree-list-holder-inner
            gap: 0.4rem

        .ant-tree-treenode
            padding: 0.2rem 0.5rem
            background: #FBFBFB