.ant-radio-checked .ant-radio-inner
	border-color: $font-blue-color !important

.ant-radio-inner::after
	background: $font-blue-color 

.ant-radio-group
	display: flex
	flex-direction: column
	gap: 0.5rem
	width: 100%