$height-value: calc(132vh + 70px)

.permission-security-header
	background: #fff
	padding: 0
	margin: 0
	border-bottom: solid 1px #D7D7D7
	border-radius: 0 !important
	height: auto !important

.permission-security-footer
	display: flex
	justify-content: flex-end
	gap: 10px
	margin: 0
	padding: 16.8px 25px
	background: #fff
	border-top: solid 1px #D7D7D7
	border-radius: 0 !important
	.ant-checkbox-wrapper
		align-items: center
		padding-bottom: 4px
		.ant-checkbox
			margin-bottom: 5px
.permission-security-layout
	background: #fff
	margin: 0 !important
	padding: 0 !important
	border: solid 1px #D7D7D7
	border-radius: 0 !important

.permission-security-container.select-all
	max-height: 810px!important
	.section-users-list
		max-height: 810px!important
	.ant-radio-group
		max-height: calc(810px - 33px) !important
	.ant-tabs-tabpane
		max-height: calc(810px - 33px) !important
	.ant-tabs-card
		max-height: 810px !important
	.users-list
		max-height: calc(810px - 34px) !important
	.checkbox-container
		max-height: calc(810px - 195px)

.permission-security-container
	margin: 25px
	padding: 0
	display: flex
	height: $height-value !important
	max-height: 740px !important

	.permission-groupUser-tab-container
		width: 400px
		.ant-tabs-nav
			margin-bottom: -1px
		.ant-tabs-content-holder
			border: 1px solid #ccc
			.ant-radio-wrapper-checked
				background: #E8F2F9
	.span-button
		margin-left: 5px

	.section-users-list
		border: 1px solid #d7d7d7
		border-right: none
		height: calc($height-value - 32px)
		max-height: calc(706px - 32px)!important

	.ant-radio-group
		gap: 0
		height: calc($height-value - 33px)
		max-height: calc(740px - 33px - 32px)!important

	.ant-tabs-tabpane
		height: calc($height-value - 33px)
		max-height: calc(740px - 33px)!important

	.ant-tabs-card
		height: $height-value
		max-height: 740px!important
	.contract-permission-content
		display: flex
		justify-content: space-between
		flex-direction: column
		align-content: space-between
		flex-wrap: wrap
		height: 100%
		.ant-row
			width: 100%
		.contract-type-table
			.title-colunm-checkbox
				display: flex
				align-items: center
				justify-content: start
				gap: 10px
	.checkbox-content
		padding: 0 23px
		box-sizing: border-box!important
		height: calc( 100% - 22px )
		.sales-hierearchy-permission-content
			display: flex
			flex-direction: column
			justify-content: space-between
			height: 100%
			align-items: flex-end
			.footer-content
				width: 100%
				justify-content: flex-end
			.gs-main-button
				padding: 0px 45px
		.gs-table
			.title-colunm-checkbox
				display: flex
				align-items: center
				justify-content: start
				gap: 10px
		.sales-hierarchy-table-permissions
			#revenue-container .ant-table-cell
				padding: 5px
			.ant-table table
				border-collapse: collapse !important
			.ant-table-selection-column
				border: 1px white
				padding: 0
			.expand-button
				color: #fff
				display: flex
				justify-content: center
				align-items: center
				color: #fff
				height: 22px
				svg
					font-size: 22px

	.ant-checkbox-group
		display: flex!important
		flex-direction: column
		flex-wrap: wrap
		height: 100%

	.ant-checkbox-wrapper,
	.ant-checkbox-group-item,
	.ant-checkbox-group .ant-checkbox-wrapper
		display: flex
		width: auto!important
	.checkbox-padding
		padding: 8px 0

	.business-units-container
		height: 100%

	.business-units-container header
		border-bottom: 1px solid #d7d7d7

	.business-units-container footer
		border-top: 1px solid #d7d7d7

	.users-list
		padding: 0
		overflow-y: auto
		overflow-x: hidden
		margin: 0
		height: calc($height-value - 36px - 32px)
		max-height: 634px !important

	.user-row
		list-style: none
		border-bottom: 1px solid #d7d7d7
		background-color: #FFF

	.user-row label
		cursor: pointer
		width: 100%

	.user-row:hover
		background-color: #FFFBD8

	.ant-radio
		margin: 0 10px

	.ant-radio-wrapper
		margin-right: 0

	.ant-radio-wrapper span:nth-child(2):not(.ant-radio-inner)
		padding: 4px 10px
		border-left: 1px solid #d7d7d7
		text-transform: capitalize
		text-overflow: ellipsis
		overflow: hidden

	.permissions-title-content
		display: flex
		flex-direction: column
		justify-content: space-between
		margin: 23px

		h1
			font-weight: normal
			margin: 0
			font-size: 23px
			line-height: 23px

		h3
			color: #104B77
			font-size: 15px
			text-transform: capitalize
			line-height: 15px

	.users-list-header
		height: 34px
		background-color: #44759A
		color: white
		border-bottom: 1px solid #d7d7d7
		border-right: none

	.users-list-header span
		border-left: 1px solid #d7d7d7
		height: 100%
		display: block
		margin-left: 35.990px
		padding-left: 10px
		line-height: 34px

	.checkbox-container
		padding-top: 18px
		height: calc( $height-value - 195px )
		max-height: calc(740px - 195px)

	.checkbox-container-info
		display: flex
		align-items: center
		justify-content: space-between
		margin-right: 25px
		margin-left: 25px
		margin-bottom: 10px

		.checkbox-container-info-part
			display: flex
			align-items: center
			min-width: 360px
			gap: 10px

	.info-wrapper
		display: flex
		align-items: center
		gap: 5px

	.ant-input-group-wrapper
		width: 100%
		.ant-input-group-addon
			svg
				height: 16px
				width: 16px
				margin-top: auto
				margin-bottom: auto
				display: block
				color: #8B8B8B

	.ant-btn-primary
		text-shadow: none

	.btn-consult
		width: auto!important
		border: 1px solid #333333!important
		background-color: white!important
		display: flex
		align-items: center
		height: 35px

	.btn-consult span,
	.btn-consult svg
		color: #333333

	.permission-security-area-list
		margin: 0 !important
		border-radius: 0 !important
		border-right: solid 1px #D7D7D7
		max-width: 250px !important
		width: 250px !important
		flex: 0 0 250px !important

		.permissions-item-menu
			display: flex
			justify-content: space-between
			align-items: center
			svg
				display: none

		.ant-menu-item-selected
			svg
				display: block

	.permissions-list-input
		margin: 16px 16px 0px 16px
		width: calc(100% - 32px)

.permissions-modal-container
	.modal-item-list-container
		overflow: auto
		max-height: calc(100vh - 300px)
	.icon-text-modal span
		text-transform: capitalize

	.ant-modal
		width: 700px!important

	.modal-paragraph
		font-weight: 500
		margin-bottom: 5px

	.item-modal
		display: flex
		justify-content: space-between
		align-items: center
		border: 1px solid #D7D7D7
		border-radius: 4px
		padding: 5px 10px
		margin-bottom: 5px
		background-color: #FCFCFC

	.item-modal:last-child
		margin-bottom: 0

	.icon-text-modal
		display: flex
		align-items: center
		gap: 13px

	.icon-text-modal svg
		color: #005CA3

	.trash-item-modal
		cursor: pointer
		display: flex
		align-items: center

	.ant-modal-footer .ant-btn.ant-btn-primary
		background-color: #0065B3

.open-modal-permission-btn
	background: #0065B3!important
	&:disabled
		background: #d7d7d7!important
