.gutter
	background-color: #eee
	background-repeat: no-repeat
	background-position: 50%

	&-horizontal
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==')
		width: 2% !important
		cursor: e-resize
		transition: background-color 0.25s
		margin: 0 10px 0 0

		&:hover
			background-color: #597991
			opacity: 0.8
