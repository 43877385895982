.empty-list-container
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    h3
        font-size: 18px
        font-weight: 400
        margin: 10px 0 0
        color: #424242
    p
        color: #424242

.list-of-added-items
    border-top: 1px solid #cccccc
    margin-top: 20px
    min-height: 150px
    width: 100%
