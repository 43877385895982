.investment-accounting-modal
    .ant-btn-primary
        color: white
        &:disabled
            background: #F5F5F5
            border: 1px solid #DBDBDB
            color: #ADADAD
            &:hover
                background: #F5F5F5 !important
                border: 1px solid #DBDBDB !important
                color: #ADADAD !important

.ellipsisCostCenter
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis