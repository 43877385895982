.gs-tab
    .ant-tabs-nav-operations
        display: none !important
    .ant-tabs-centered
        height: 100%

        .ant-tabs-content
            height: 100%

    .ant-tabs-nav-list
        width: 100%

        .ant-tabs-tab
            width: 50%
            display: flex
            justify-content: center
            margin: 0 !important
            border: 1px solid #D7D7D7 

            &:hover
                color: #0065b3

        .ant-tabs-tab-active
            background: #FFF
            border-top: 3px solid #0065b3
            border-right: 1px solid #0065b3
            border-left: 1px solid #0065b3

            div
                color: #0065b3
