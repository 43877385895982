.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight
	padding-top: 0

.ant-popover-inner-content
	padding: 3px 16px

.ant-popover-inner
	border: 1px solid #D7D7D7 !important
	box-shadow: 0px 4px 4px 0px #00000040 !important
	box-sizing: border-box
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
	border-radius: 9px

.ant-popover-arrow
	display: none !important

.ant-popover-title
	border-bottom: none

.popconfirm-delete
	.ant-popover-inner
		padding: 5px 15px
		button
			height: 32px
			border-radius: 5px
