#contract-values-main
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table
        border-top: none
    .undo-title-and-planning
        margin: 0 1.5rem

.contract-values-main

    .top-button-contract-value
        align-items: center
        display: flex
        width: 100%
        margin-left: 13px

        .calculate-button
            border: none
            box-shadow: none
            svg
                font-size: 22px
            &:disabled
                background: #fff

    .contract-info
        display: flex
        align-items: flex-end
        justify-content: space-between
        margin: 13px 25px

        .contract-name
            display: flex
            flex-direction: column
            font-weight: 400
            font-size: 12px
            line-height: 15px

            .contract-label
                display: flex
                align-items: center
                float: right
                gap: 0.5rem
                border-radius: 4px
                padding: 4px 8px
                background-color: #E8F2F9
                margin: 8px 16px 0px 0px
                p
                    color: #0065B3
                    font-weight: 400
                    font-size: 16px
                    line-height: 16px
                    margin: 0

    .table-contract-values
        border-radius: 0
        margin-left: 12px
        margin-right: 12px

        .ant-table-content
            overflow-x: scroll
            overflow-y: scroll !important
            height: calc(100vh - 280px)
        thead
            position: sticky
            top: 0
            z-index: 3
            th
                white-space: nowrap
        .ant-table-thead
            tr:first-child
                th
                    background-color: #0D2F48

    .top-table-info
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: flex-end
        margin: 0px 25px
