#summary-cards-container-accounting-ledger
    display: flex
    align-items: center
    justify-content: center
    gap: 8px
    height: 62px !important
    margin-top: 20px

#card-container-accounting-ledger
    display: flex
    align-items: flex-end
    justify-content: space-evenly
    min-width: 175px
    border: 1px solid #ccc
    border-radius: 4px
    padding: 8px 15px
    height: 100%

    & svg
        margin-right: 12px
        font-size: 24px
        color: #4F9ACF
        margin-bottom: 12px

#card-content-accounting-ledger
    display: flex
    flex-direction: column
    justify-content: space-around
    height: 72px !important
    align-items: center

#card-title-accounting-ledger
    font-size: 13px
    color: #333
    margin: 0

#card-content-accounting-ledger
    p
        font-size: 13px
        color: #333
        margin: 0
        height: 30px
    span
        font-size: 18px
        font-weight: 500
