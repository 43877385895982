#top-buttons-additional-fields
	padding-right: 24px
	> div
		width: calc(100% - 20px)
		display: inline-block
	
.budget-additional-fields

	.additional-fields-table
		padding: 0 23px
		.ant-table-row:hover
			.field-column svg
				opacity: 0.8

		.field-column
			display: flex
			align-items: center
			justify-content: space-between
			gap: 5px

			&:hover
				opacity: 1

			& > svg
				font-size: 1rem
				opacity: 0

			p
				margin: 0

	.gs-table
		padding: 0 24px

.budget-additional-fields-modal

	.additional-fields-modal
		min-width: 1100px !important
		margin: 1rem 0 3rem 0 !important

	.form-content

		.form-radio-active
			flex-direction: row

		.form-input-fields
			border: 1px solid #D7D7D7
			background: #FCFCFC
			border-radius: 3px
			padding: 8px

			.row-header

				p
					margin-bottom: 0.5rem
					font-weight: 600

			.form-checkable

				.ant-checkbox-group

					.ant-checkbox-group

					.ant-checkbox-wrapper
						padding: 0.3rem 0
						margin: 0

		.form-footer

			.ant-col
				display: flex
				justify-content: end

				.gs-secondary-button
					margin: 10px 0 0 0 !important

#field-modal-formula
	padding-bottom: 1rem

	h3
		font-weight: 400
		color: #3F3E44

	.attribute_parameterization-new-attribute-modal-formula-workarea
		display: flex
		align-items: center
		justify-content: flex-start
		border: 1px solid #D7D7D7
		border-radius: 3px
		background: #FCFCFC
		padding: 10px
		gap: 8px
		min-height: 60px
		// border: 2px solid red

		.attribute_parameterization-new-attribute-modal-formula-workarea-tag
			position: relative
			background: #E8F2F9
			line-height: 16px

			span
				position: absolute
				top: -20%
				right: -10%
				padding: 2px
				background: #DBDBDB
				color: #5C5C5C
				border-radius: 50%
				font-size: 10px
				cursor: pointer
				visibility: hidden

		.attribute_parameterization-new-attribute-modal-rule
			display: flex
			justify-content: center
			align-items: center
			border: 1px solid #D7D7D7
			border-radius: 3px
			background: #FCFCFC
			height: 34px
			width: fit-content
			// padding: 20px
			padding-left: 16px
			box-sizing: border-box
			gap: 11px

			.attribute_parameterization-new-attribute-modal-rule-label
				display: flex
				justify-content: center
				align-items: center
				padding: 1px 8px
				height: 18px
				background: #E8F2F9
				font-weight: 400
				color: #0065B3
				border-radius: 2px

.form-field-operations
	margin-left: 20px

	.operations-select-buttons
		display: flex
		margin-top: 5px

		button
			margin-right: 25px