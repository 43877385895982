@import "components/variable"

#report_view-container
    font-family: 'Open Sans', sans-serif
    background: #fff
    padding: 0

    #revenue-row-filter
        .revenue-period-filter-card
            .ant-picker-input
                input
                    font-family: Open Sans
                    font-size: 14px
                    font-style: normal
                    font-weight: 600
                    color: #7A7A7A !important

    #revenue-period-filter-card-popover
        display: flex
        align-items: center
        justify-content: space-between
        cursor: pointer
        height: 56px
        line-height: normal
        max-width: 230px
        padding: 24px 8px
        border-left: 1px solid #DBDBDB
        p.current-filter-value
            font-size: 13px
            margin: 0
        p.filter-name
            color: #072D49
            font-weight: 500
            margin: 0
            padding-left: 10px !important
        .filter-popover
            display: flex
        .filter-value
            display: flex
            align-items: center
            justify-content: flex-start
            width: 150px
            text-overflow: ellipsis
            overflow: hidden
            p.filter-selected-value
                max-height: 20px
                margin: 0
                text-overflow: ellipsis
                overflow: hidden
                white-space: nowrap
                margin-left: 10px
        svg.open-filter-icon
            font-size: 18px
            min-height: 45px
        .ant-picker
            background-color: #fff
            padding: 0
            input
                font-size: 13px
                font-weight: normal
            .ant-picker-active-bar
                margin-left: 0
                max-width: 50px
        .gs-date-content
            gap: 0
            padding: 0
            background-color: #fff
            height: 22px
            margin-right: 20px
            svg.gs-date-icon
                display: none
            svg.gs-date-separator
                margin: 4px
                margin-bottom: -6px
            input
                font-weight: normal
                font-size: 13px
                text-align: center
                margin-bottom: -8px
            &:hover
                cursor: pointer
                border-bottom: 2px solid #1890ff

    .report-table
        width: 99%
        margin: auto

        .ant-table-thead th.ant-table-cell
            white-space: nowrap
            width: auto

        td.ant-table-cell:first-child
            padding-right: 50px
        td.ant-table-cell:nth-child(n+2)
            padding-left: 100px

    .gs-table .ant-table-tbody
        .report-table-cell-text
            color: #424242
            margin: 0

        .button-expandable-tree
            display: flex
            align-items: center
            justify-content: center
            position: static
            float: left
            text-align: unset
            line-height: 25px

    .button-expandable-tree
        width: 25px
        height: 25px
        float: left
        position: relative
        cursor: pointer
        user-select: none
        text-align: center
        font-size: 15pt
        color: #717171
        line-height: 25px

    #report_view-title
        display: flex
        width: 100%
        justify-content: space-between
        padding: 0 0 0 25px

        div.ant-picker.ant-picker-range
            width: 100%

        p.filter-name
            color: #5C5C5C
            margin-right: 4px

            &::after
                content: ":"
        div.ant-picker-input
            width: 60px

        div.ant-picker-input input
            color: #5C5C5C

        div.ant-picker-range-separator
            width: 25%
            text-align: center

    #filters-container
        display: flex
        justify-content: space-between
        align-items: center
        padding: 20px 14px

        #filters-options
            svg
                color: #333333

            @media (max-width: 1400px)
                width: 70%

        #filters-buttons
            display: flex

            .comparative_button
                border: 1px solid #0D2F48
                border-radius: 4px
                color: #0D2F48
                font-size: 1rem
                font-weight: 400
                display: inline-flex
                justify-content: center
                align-items: center
                margin: 0
                margin-right: 8px
                margin-left: 16px
                padding: 6px 16px
                transition: background-color 0.8s ease

                &:hover
                    background-color: #0D2F48
                    color: #FFFFFF

            .load_report_button
                border: 1px solid #0065B3
                border-radius: 4px
                background: #0065B3
                color: #FFFFFF
                display: inline-flex
                justify-content: center
                align-items: center
                font-size: 1rem
                font-weight: 400
                margin: 0
                padding: 6px 16px

                &:hover
                    background: #0a2a42

                &:disabled
                    cursor: not-allowed

        .filter-config-buttons
            border: none
            box-shadow: none

            svg
                color: #333333
                font-size: 28px

        .ant-row
            position: static

        div.revenue-period-filter-card:first-child
            border-left: 1px solid #DBDBDB

        div.revenue-period-filter-card
            padding-left: 10px
            border-left: 1px solid #DBDBDB

            .open-filter-button svg.open-filter-icon
                color: #717171

        p.filter-name
            color: #072D49
            font-weight: 600

        p.current-filter-value
            color: #7A7A7A
            font-weight: 600

    .ant-layout-header
        margin: 0
        padding: 0
        display: flex
        justify-content: space-between

        .open-filter-button
            max-width: none

            div.ant-space.ant-space-vertical
                flex-direction: row
                padding: 0
                margin: 0
                height: 100%

            .ant-picker.ant-picker-range
                width: 220px

        .ant-space#report_view-header-title
            h3
                font-weight: 400
                color: #424242

            .ant-space-item
                width: auto

                .coachmark-button
                    border-radius: 4px
                    margin-top: 25px
                    margin-left: 10px

                    svg
                        font-size: 26px
                        color: #0065B3

    .title_divider
        margin: 0
        height: 1px
        background: #D7D7D7

.report_view_modal
    #report_view_modal_container
        & > div
            display: flex

        .loading-container
            height: 200px

        #report_view_modal_filters_description
            display: flex
            flex-direction: column
            width: 100%,
            padding: 13px 23px
            padding-right: 0
            height: 370px

            & > div
                display: flex
                flex-direction: column
                border: 1px solid #DBDBDB
                border-radius: 2px
                min-height: 343px
                overflow: auto

            .report_viewer_description_block
                background-color: #FFFFFF
                padding: 4px 16px 0 16px
                font-size: 14px
                color: #333333
                cursor: pointer
                border: none
                text-align: left
                padding-top: 10px

                &:first-child
                    padding-top: 10px

                &:last-child
                    padding-bottom: 10px

                    span
                        display: none

                span
                    display: block
                    height: 1px
                    width: 100%
                    background-color: #DBDBDB
                    margin-top: 11px

            .report_viewer_description_block_active
                background-color: #E8F2F9

                span
                    background-color: #E8F2F9

        #report_view_modal_filters_arrow
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center

            #report_view_modal_filters_arrow_icon_wrapper
                background-color: #F1F2F3
                padding: 10px 3px
                display: flex
                justify-content: center
                align-items: center

        #report_view_modal_filters_checkboxes
            display: flex
            flex-direction: column
            width: 100%,
            padding: 13px 23px
            padding-left: 0
            height: 370px

            .ant-checkbox-checked .ant-checkbox-inner
                background-color: #0065B3 !important
                border-color: #0065B3 !important

            & span.ant-input-affix-wrapper
                margin-bottom: 17px

            & > div
                display: flex
                flex-direction: column
                border: 1px solid #DBDBDB
                border-radius: 2px
                min-height: 343px
                border-left-width: 20px
                border-left-color: #F1F2F3
                overflow: auto

            & div.ant-checkbox-group label
                margin: 0

#configurations_pop_over_filters
    display: flex
    flex-direction: column

    #configurations_pop_over_title
        display: flex
        justify-content: center
        align-items: center
        gap: 123px
        padding: 15px 0 0 0

        h3
            margin: 0 !important

        svg
            cursor: pointer
            font-size: 20px

    #configurations_pop_over_preferences
        display: flex
        flex-direction: column
        margin-bottom: 22px

        & > span
            margin-bottom: 16px
            color: #5C5C5C

        .ant-radio-group label
            width: auto !important
            margin: 0
            padding: 0

        div.ant-radio-group label span.ant-radio-checked span.ant-radio-inner
            background-color: #fff !important
            border-color: #0065B3 !important

        span.ant-radio-inner::after
            background-color: #0065B3 !important

    #configurations_pop_over_decimal_houses
        display: flex
        flex-direction: column

        .ant-select
            width: 50%

    #configurations_pop_over_buttons
        display: flex
        justify-content: flex-end
        align-items: center
        gap: 16px
        margin-top: 17px
        margin-bottom: 10px

        button
            background: #0065B3
            padding: 0
            width: 72px
