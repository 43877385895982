@import "components/variable"

#revenue-container
	padding: 2px 0

	.ant-space-item
		width: 100%

	.ant-table-cell
		padding: 0
		height: 0

	.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first
		text-align: center !important

	// Esconder o botão de replicar valores na ultima célula à direita
	.ant-table-row
		td:nth-last-child(2)
			div.revenue-icon-replicate-values
				button
					display: none

	.ant-table-cell.ant-table-cell-with-append.revenue-row-create-coin
		padding: 5px

	.ant-table-header
		.ant-table-selection-column
			border: none

	.button-cancel
		border: none !important

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector
		height: 25px !important

	.ant-select-single .ant-select-selector .ant-select-selection-item
		top: -4px

	.ant-table-selection-column + .ant-table-cell
		border: none

	.ant-table-header
		.ant-table-cell
			padding: 5px !important
			border: none

	.ant-layout-header
		height: 190px
		flex-direction: column
		align-items: flex-start
		.open-filter-button p, span
			line-height: normal

		#revenue-header-data-container
			width: 100%
			display: flex
			justify-content: space-between
			flex-wrap: nowrap
			flex-direction: row
			align-items: center

			.coachmark-button
				border-radius: 4px
				margin-top: 25px
				margin-left: 10px

				svg
					font-size: 26px
					color: $font-blue-color

			#revenue-header-cards-container
				.ant-card
					width: 100%
					height: 56px

					.ant-card-loading-content
						margin-top: -6px

					.ant-card-meta
						margin-top: -7px
						display: flex
						flex-direction: row
						flex-wrap: nowrap
						align-items: center

						.ant-card-meta-avatar
							font-size: 24px
							color: $font-blue-color

						.ant-card-meta-title
							font-size: 13px
							font-weight: 400
							margin-bottom: 0
							color: #5E6366

						.ant-card-meta-description
							font-size: 18px
							font-weight: 500
							color: rgba(0, 0, 0, 0.87)

					&#revenue-header-company-info-card
						border: none

						.ant-card-body
							display: flex
							flex-direction: row
							flex-wrap: nowrap
							align-items: center
							justify-content: space-between
							padding-right: 0
							gap: 14px

							.revenue-currency-icon-info
								font-size: 22px

							.revenue-currency-name-info
								font-size: 12px
								color: #717171

							.revenue-currency-unit-info
								padding: 3px 8px
								background-color: #E8F2F9
								border-radius: 2px
								font-size: 12px
								color: #0065B3

			#revenue-header-filter-container
				.ant-card
					display: flex
					justify-content: space-around
					gap: 100px

	#revenue-table-container
		margin-bottom: 24px
		padding-left: 20px
		padding-right: 20px
		.gs-table
			.ant-table table
				border-collapse: collapse !important
			.ant-table-selection-column
				border: 1px white
				padding: 0

	.button-expandable-tree
		width: 25px
		height: 25px
		float: left
		position: relative
		cursor: pointer
		user-select: none
		text-align: center
		font-size: 15pt
		color: #717171
		line-height: 25px

	#label-period-filter
		min-height: 24px
		height: min-content
		background: rgba(46, 182, 104, 0.12)
		border-radius: 2px
		margin-top: 3px
		.label-period-filter-text
			font-style: normal
			font-weight: 400
			font-size: 12px
			line-height: 16px
			color: #2EB668
			padding: 3px 7px

#revenue-table-header
	background-color: #FFFFFF
	display: flex
	padding-top: 10px
	padding-bottom: 10px
	padding-right: 5px
	padding-left: 5px
	justify-content: space-between

	.revenue-button-calculate
		svg
			font-size: 15px

.revenue-filter-order-button-save
	background: #0065B3
	border: 1px solid #0065B3
	border-radius: 4px

#revenue-card-detail-item
	display: flex
	gap: 360px
	background-color: #FBFBFB
	width: 100%

#revenue-table-revenue-card-filter-header
	display: flex
	justify-content: space-between
	margin-top: 5px

#revenue-filter-header
	display: flex
	gap: 7px
	color: #0065B3
	background-color: #DBDBDB
	padding: 5px

#revenue-card-filter
	.ant-card-body
		display: flex

.revenue-icon-replicate-values
	display: flex
	justify-content: space-between
	svg
		opacity: 0

.revenue-icon-replicate-values:hover
	svg
		opacity: 1

.revenue-icon-replicate-values-accomplished
	background: rgba(46, 182, 104, 0.12) !important
	color: rgba(46, 182, 104) !important

.revenue-hidden-row-coin
	display: none

.revenue-hidden-row-border
	border: none !important

.revenue-row-create-coin
	background-color: #F5F5F5
	border: 1px solid #D7D7D7 !important

.filters-container
	display: flex
	align-items: center
	justify-content: start
	margin: 24px 24px 24px 0
	padding-left: 0px
	padding-bottom: 20px
	width: 100%
	.filters-content
		display: flex
		align-items: center
		justify-content: start
		position: relative
		height: 50px
		overflow: hidden
		.ant-col
			border-right: 1px solid #ccc
			width: 230px
		.scroll-button
			position: absolute
			top: 10px
			border-radius: 50%
			width: 25px
			height: 24px
			font-size: 15px
			z-index: 100
			background: #0065b3
			&.next
				right: 0
				box-shadow: -10px 0px 20px 20px
			&.back
				left: 0
				box-shadow: -10px 0px 20px 20px
				transform: rotate(180deg)
		.ant-row
			width: 100%
			position: absolute
			top: 0
			transition: all 0.6s ease-out

	.ant-row
		.ant-collapse-content-box
			padding: 0

	.ant-collapse
		width: 100%
		padding: 0
		background: #ffffff
		.ant-collapse-header
			flex-direction: row-reverse
			justify-content: start
			padding-bottom: 0
			div
				margin-left: 5px
		.ant-collapse-content
			margin-top: 10px

	.switch-view-button
		display: flex
		align-items: center
		justify-content: center
		width: 100%
		margin-left: -20px
		p.filter-name
			color: #072D49
			font-weight: 500
			margin: 0
		p.current-filter-value
			font-size: 13px
			margin: 0

.revenue-period-filter-card
	.filter-name
		height: 25px
		line-height: inherit !important

.revenue-colum-principal
	border-bottom: none !important
	padding-right: 30px

.revenue-colum-principal-row-caption
	border: none !important
	.ant-table-selection-column
		border: none
	.caption-level
		padding-left: 10px
		position: relative
	.caption-level::before
		content: ''
		height: 36px
		width: 1px
		position: absolute
		top: -7px
		background-color: #D7D7D7

#scrollableDiv
	.ant-list-item
		padding: 4px 0 !important

.revenue-icon-rotate
	animation: spin 2s linear infinite
	margin-top: 4px

	@keyframes spin
		0%
			transform: rotate(0deg)

		100%
			transform: rotate(-360deg)

.disable-checkbox-revenue
	display: none
	border: none

.disable-checkbox-revenue-line-coin
	display: none
	border: none

.buttons-select-view
	gap: 20px !important

// Cópia de valores
div.copy-values-container
	display: flex
	// border: 2px solid red
	justify-content: space-between
	align-items: center

	div.editable-cell-value-wrap
		margin-top: 0
		margin-bottom: 0
		flex-grow: 8

	.copy-values-buttons
		display: flex
		flex-direction: column
		flex-grow: 1

		.copy-value-button
			cursor: pointer
			svg
				color: #0065B3
				box-shadow: none
				border: none
.realized-column
	background: rgba(46, 182, 104, 0.12)
	color: #2EB668
