.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner ,
.ant-tree-checkbox-checked:not(.ant-checkbox-disabled) .ant-tree-checkbox-inner
	background-color: $font-blue-color !important
	border-color: $font-blue-color !important

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after, .ant-checkbox-indeterminate .ant-checkbox-inner::after
	background: $font-blue-color

.ant-checkbox-group

	.ant-checkbox-wrapper
		width: -webkit-fill-available !important
		padding: 0.3rem
		margin: 10px 0 0 0

.ant-switch-checked
	background: #119A5C
