.scenario-projection-title
  margin: 0 1.5rem

#scenario-main
  margin: 0 1.5rem
  .gs-top-buttons 
    .top-tool-buttons 
      svg
        font-size: 22px 
  
  .sp-modal-talbe-locale
    color: black
    display: flex
    flex-start: left
    margin-left: 140px

  .loading-wrapper
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 9999
    width: 100%
    height: 100%
    backdrop-filter: blur(1px)

#table-scenario
  margin-bottom: 60px

.finish-simulation-btn
  padding: 0 !important
