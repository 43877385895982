.page-container
	display: flex
	flex-direction: column
	align-items: center
	user-select: none
.header-container
	display: flex
	align-items: center
	justify-content: center
	width: 100%
	height: 199px
	background: #F1F2F3

.header-content
	display: flex
	align-items: center
	justify-content: center
	width: 95%

	& > img
		width: 149px

.header-right-content
	display: flex
	flex: 1
	align-items: flex-end
	justify-content: space-between
	width: 95%
	margin-left: 40px

.header-right-content-box1
	display: flex
	flex-direction: column
	align-items: flex-start
	justify-content: center

	h1
		margin-top: 20px
		margin-bottom: 16px
		font-size: 28px
		font-weight: normal
		height: 32px
		line-height: 32px

	p
		text-align: left
		max-width: 400px
		color: #404040
		font-size: 15px
		margin-bottom: 3px

#module-name
	display: flex
	align-items: center
	padding: 2px 5px 2px 8px
	background: #BBD5E8
	border-radius: 10px
	margin-top: 22px

	h4
		color: #104B77
		margin: 0 8px
	svg
		font-size: 16px
		margin: 0
		color: #104B77

.card-wrapper
	display: flex
	flex-wrap: wrap
	align-items: center
	justify-content: flex-start
	margin: 30px 0

.btn-open-coachmark
	border: none
	background: transparent
	box-shadow: none
	color: #0065B3
	display: flex
	align-items: center
	padding: 0

	&:hover,&:focus
		background: transparent
		color: #0065B3

#icon-coachmark
	font-size: 20px
	border: 1px solid #bbb
	border-radius: 4px
	margin-right: 5px
	background: #fff

#btn-manage-cards
	display: flex
	align-items: center
	justify-content: center
	border: 1px dashed #4F9ACF
	border-radius: 8px
	color: #4692C8
	width: calc(20% - 10px)
	height: 51px
	padding: 0 15px
	margin: 10px 10px 0 0

	&:hover
		background: rgba(79, 154, 207, 0.08)

	h3
		color: #4692C8
		margin: 0 10px
		font-weight: normal
	svg
		border: 1px dashed #4692C8
		border-radius: 50%
		font-size: 20px
		padding: 2px
		color: #4692C8

.loading-spin-container
	display: flex
	justify-content: center
	margin-top: 120px
