#modal-header
	color: #fff
	background: #44759A
	padding: 4px 20px

#modal-content
	list-style: none
	border: 1px solid #bbb
	padding: 0
	max-height: 55vh
	overflow-y: auto
	margin-bottom: 0

	li
		padding: 4px 15px
	li:not(:last-child)
		border-bottom: 1px solid #bbb