.df-filters-container
	display: flex
	align-items: center
	justify-content: space-between
	margin: 24px 24px 0
	padding-bottom: 20px
	.ant-carousel
		width: calc(96% - 330px)
		.gs-carousel
			.slick-arrow.slick-next
				z-index: 2 !important
			.slick-arrow.slick-prev
				z-index: 2 !important
	.ant-select-selector
		height: 51px !important
		align-items: center

	.gs-flex 
		display: flex


	.gs-secondary-button
		height: 51px