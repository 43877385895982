.allocation-parameterization-page
	.table-content
		padding: 24px

.new-allocation-modal
	.accounting-account-column
		display: flex
		justify-content: space-between
		&>div
		p
			margin: 0
	.add-button-col
		display: flex
		align-items: center
	.ant-modal-footer
		display: flex
		justify-content: end
	.open-filter-button
		height: 33px
		width: 100%
		padding: 0 10px
		max-width: none
	.filter-base-column
		padding: 0 !important
		.disabled-field
			cursor: not-allowed
			background: #f1f1f1
		.ant-space-vertical
			.ant-space-item
				&:first-child
					display: none
