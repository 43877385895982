.distribution-modal-container
    max-height: calc(100vh - 100px)
    .ant-modal-body
        padding: 0
    .ant-tabs-content-holder
        margin: 0 24px 8px
    .dist-period-inputs-container
        display: flex
        align-items: center
        justify-content: center
        button
            display: flex
            align-items: center
            gap: 5px
        .ant-input-number-affix-wrapper
            width: 160px
            margin-right: 20px
        .ant-radio-group
            flex-direction: row
    .dist-period-remainingAmount-container
        display: flex
        align-items: center
        justify-content: space-between
        margin-bottom: 6px
        p
            margin: 0
        button
            display: flex
            align-items: center
            gap: 5px
    .dist-modal-table-input-cell
        display: flex
        align-items: center
        justify-content: center
        position: relative
        .dist-modal-replicate-buttons-container
            opacity: 0
            display: none
            transition: all 0.2s
            flex-direction: column
            align-items: center
            justify-content: center
            position: absolute
            right: 4px
            svg
                font-size: 18px
                color: #0065b3
                cursor: pointer
        &:hover
            .dist-modal-replicate-buttons-container
                opacity: 1
                display: flex

    .gs-table
        .ant-table-tbody > tr.ant-table-row-selected:not(.blocked-month) > td
            background: #fff
        .ant-table-tbody > tr.ant-table-row-selected.blocked-month > td
            background: #f5f5f5
        .ant-table-summary tr td
            padding: 4px
        .month-column
            padding-left: 5px
        input
            width: 80px
        tbody
            .dist-modal-table-input-cell:hover
                background: #E8F2F9
            td
                height: 33px
                padding: 0
        .editable-cell-value-wrap
            padding: 8px
            cursor: pointer
            .ant-form-item
                padding: 0 !important

        .editable-row:hover .editable-cell-value-wrap
            border: 1px solid #d9d9d9
            border-radius: 2px
