#top-buttons-detail-layout
    padding-right: 24px
    > div
        width: calc(100% - 20px)
        display: inline-block

#main-detailLayout-page

    .gs-table
        padding: 0 24px
        thead
            .ant-table-selection-column label
                display: none
        tr:nth-child(even)
            background-color: #FFF !important
        .detail-row-container
            display: flex
            align-items: center
            justify-content: start
            gap: 10px
            p
                margin: 0
    .column-table
        padding-left: 15px !important

.modal-detailLayout-page
    .input-content
        display: flex
        align-items: center
        justify-content: start
        width: 100%
        gap: 20px

    .input-content .ant-select
        width: 100% !important

    .detailLayout-detail-form-container
        display: flex
        flex-direction: column
        align-items: start
        justify-content: start
        width: 100%
        gap: 20px
        & > .ant-row
            width: 100%
            margin: 0 !important
            gap: 16px
            .ant-col
                padding: 0 !important

        .ant-col-submit-button
            padding: 0px !important
            display: flex
            align-items: center
            flex: 0 0 auto !important
            
        button
            margin: 0

    .gs-secondary-button
        margin-bottom: 24px
        &:disabled
            background-color: #fcfcfc

    .plus-button svg
        color: #0065B3!important

    .icon-text-modal
        display: flex
        align-items: center
        gap: 13px

    .icon-text-modal svg
        color: #005CA3

    .trash-item-modal
        cursor: pointer
        display: flex
        align-items: center

        svg
            width: 14px
    .ant-btn,
    .ant-btn-default
        box-shadow: 0 0 0 rgb(0 0 0 / 0%)

    .detail-list-empty
        border-top: 1px solid #ccc
        padding-top: 60px
        display: flex
        flex-direction: column
        align-items: center
        row-gap: 5px

    .detailLayout-list-item
        display: flex
        justify-content: space-between
        align-items: center
        border: 1px solid #D7D7D7
        border-radius: 4px
        padding: 5px 10px
        background-color: #FCFCFC
        width: 100%

        .ant-col:nth-child(2) span
            display: inline-block
            background-color: #BCC5C9
            text-transform: uppercase
            padding: 0px 10px
            border-radius: 10px
            font-size: 11px

    .ant-collapse-header
        padding: 0 !important
        & > div:first-child
            height: 100%
            padding: 10px 0 0 10px
            svg
                margin-right: 4px
    .ant-collapse-content
        border-top: none
        .ant-collapse-content-box
            padding: 0
    .ant-collapse-arrow
        font-size: 16px

    .gs-table
        thead tr th
            padding-left: 8px
        .ant-table-tbody > tr.ant-table-row-selected > td
            background: #fff
        input
            width: 70px
        tbody
            .editable-cell-value-wrap:hover
                background: #E8F2F9
            td
                padding: 0
        .editable-cell-value-wrap
            padding: 6px
            cursor: pointer
            .ant-form-item
                padding: 0 !important

        .editable-row:hover .editable-cell-value-wrap
            border: 1px solid #d9d9d9
            border-radius: 2px
