#top-buttons-cost-category
    padding-right: 24px
    > div
        width: calc(100%  -  20px)
        display: inline-block

.cost-category-import-menu
    svg
        margin-left: auto
        display: block
        margin-right: 24px
    
.cost-category-selecteds thead.ant-table-thead > tr > th.ant-table-cell
    background: none

.cost-category-name-column
    padding-left: 15px

#cost-category-form
    width: 100%
    .inputs-container
        display: flex
        flex-direction: column
        align-items: end
        width: 100%
        .ant-row
            width: 100%

.list-of-added-items
    .cost-category-list-header
        .ant-row
            border: none
            height: 22px
            font-weight: 500
            margin-top: 10px
            .ant-col
                padding-left: 8px

    .cost-category-list-content
        max-height: 250px
        overflow: auto
        padding-left: 1px
        .ant-row
            width: 100%
            height: 32px
            margin-top: 6px
            border: 1px solid #ccc
            border-radius: 4px
            padding: 0 12px
            overflow: hidden
            .ant-col
                display: flex
                align-items: center
                justify-content: flex-end
            .edit-button
                font-size: 17px
                margin-top: 6px
            button
                height: 29px
                border: none
                padding: 0
                background: transparent
            svg.delete-icon:hover
                color: #CC0000
                cursor: pointer

    .cost-category-list-empty
        margin: 60px auto 20px
        display: flex
        flex-direction: column
        align-items: center
        row-gap: 5px
