.detail-info
	width: calc(100% - 230px)
	display: flex
	gap: 100px
	justify-content: space-between
	align-items: center
	padding: 16px
	.ant-carousel
		width: calc(100% - 300px) !important
		.slick-current
			&>div
				&>div
					width: 280px !important
					box-sizing: border-box

#grid-content
	padding: 15px 24px 0
	width: 100%
	background-color: white
	position: relative
	margin-bottom: 32px

	.grid-operations-container
		display: flex
		align-items: center
		justify-content: space-between
		button.grid-operation-button,span.grid-operation-button button
			background: transparent
			border: none
			transition: all 0.2s
		svg
			font-size: 16px

		.trash
			svg
				transition: all 0.3s
			svg:not(:disabled):hover
				color: #5c5c5c !important
		.grid-operations-left-content
			display: flex
			align-items: center
			justify-content: start
			.viewMode-close-btn
				display: flex
				align-items: center
				border: none
				color: #0065B3
				background: #D9E4ED
				padding: 0 0 0 12px
				font-size: 14px
				height: 25px
				margin-top: -2px
				p
					margin: 0
				span
					border-radius: 5px
					background: rgba(0, 101, 179, 0.1)
					color: #424242
					padding: 4px 10px
					margin-left: 5px
					height: 25px
					font-size: 12px
					svg
						margin-right: 4px
						font-size: 10px
		.grid-operations-right-content
			display: flex
			align-items: center
			justify-content: end
			.ant-input-affix-wrapper
				width: 250px !important
			.successfully-saved-budget-alert
				background: rgba(46, 182, 104, 0.12)
				border: none
				transition: all 0.3s ease 0s
				margin-top: -10px
				border-radius: 3px
				padding: 4px 15px 4px 10px
				svg
					color: #2EB668
					font-size: 20px
				.ant-alert-message
					color: #2EB668

	.gs-table
		tr:not(.add-detail-btn-row)
			td.frozen-column
				background: #BFE4FF
			td.realized-column
				background: rgba(46, 182, 104, 0.12)
				color: #2EB668
			td.red-column
				color: red
			td.blocked-column
				background-color: #F0F0F0 !important
				color: #252525 !important
			td.revision-period-column
				background-color: #f8efdc
				color: #b48527
			td.out-of-period-column
				opacity: 0.8
		.ant-table-summary
			.ant-table-cell
				padding: 8px
		.ant-table-container
			border-left: none
		thead tr th
			padding: 0
			&:last-child
				padding: auto

		.editable-cell
			position: relative
		.ant-table-selection-column
			padding: 8px
		tbody
			.editable-cell-value-wrap:hover
				background: #E8F2F9
			td
				height: 40px
				padding: 0
		.editable-cell-value-wrap
			padding: 8px
			cursor: pointer
			width: 100%
			.ant-form-item
				padding: 0 !important

		.editable-row:hover .editable-cell-value-wrap
			border: 1px solid #d9d9d9
			border-radius: 2px

		.ant-table-row-level-1:not(.ant-table-row-selected)
			background: #fbfbfb !important

			.ant-table-cell-fix-left
				background: #fbfbfb
			&:hover
				background: #fbfbfb !important

		.ant-table-row-level-1
			.table-first-column
				text-align: left
				font-size: 13px
				p
					font-size: 13px

		.add-detail-btn-row
			.ant-table-row-expand-icon
				display: none
			.ant-form .ant-form-item .ant-form-item-label
				padding: 0
			td:not(:last-child)
				border-right: none
			.table-first-column
				padding: 0
			.add-new-detail-btn
				display: block
				border: none
				color: #0065B3
				align-items: center
				display: flex
				gap: 5px
				font-size: 13px

		input
			max-width: 60px

		.table-first-column
			padding: 0 0 0 4px
			p
				margin: 0
				display: flex
				justify-content: space-between
				align-items: center

			.currency-label
				background: #BCC5C9
				font-size: 10px
				font-weight: 500
				padding: 0 8px
				border-radius: 20px
				margin: 0 10px

		.new-detail-form-container
			display: flex
			align-items: center
			width: 1010px
			gap: 20px
			//margin-bottom: 20px
			.gs-main-button
				margin-left: 20px
				display: 'block'
				padding: 0 30px
			.ant-btn-text
				margin-left: -10px
		.hidden-column
			display: none !important

		.df-table-quantity-row,
		.df-table-unitValue-row
			background: #fbfbfb !important
			.table-first-column
				display: flex
				align-items: center
				font-size: 12px

				& > span:last-child
					height: 100%
					display: flex
					align-items: center
					border-left: 1px solid #ccc
					padding-left: 35px
					margin-left: -4px
		.currency-rate-container
			display: flex
			flex-direction: column
			align-items: start
			justify-content: start
			.currency-rate
				margin-bottom: 0px !important
	.ant-table-content
		table
			border-top: none !important

	.ant-table-row
		background-color: #fff !important

	.ant-table-cell
		svg:hover
			cursor: pointer

.table-header-percent
	background: #DFE4E9 !important
	color: #424242 !important
	border: 1px solid #D7D7D7 !important
	font-weight: 400 !important
	height: 35px

.table-header-edge
	background: #F1F2F3 !important
	color: #424242 !important
	border: none !important

.currencies-popover
	.ant-popover-inner-content
		padding: 0
	.ant-popover-title
		padding: 10px 15px
		background: #f1f2f3
		border-top-left-radius: 9px
		border-top-right-radius: 9px
	.filter-list-container
		padding: 0

.left-buttons-popover-container
	width: 100%
	display: flex
	align-items: center
	gap: 10px
	justify-content: center
	padding: 10px
	button
		min-width: 100px

.currencies-popover-container
	width: 300px
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	gap: 10px
	padding: 10px
	div:not(.filter-list)
		display: flex
		flex-direction: row
		button
			min-width: 100px

.dropdown-cell-options-container
	border-radius: 4px
	.ant-dropdown-menu
		border-radius: 8px

.detail-trash-button
	cursor: pointer

.grid-operation-importExport-btn
	display: flex
	align-items: center
	gap: 10px
	color: #333
	&:hover
		background: unset

.grid-operations-viewMode-popover-container
	display: flex
	flex-direction: column
	align-items: center
	justify-content: space-between
	width: 250px
	.ant-checkbox-group
		padding: 0 10px
		.ant-checkbox-wrapper
			margin: 5px 0 0 0
	.grid-operations-viewMode-popover-footer
		display: flex
		align-items: center
		width: 100%
		justify-content: center
		margin-top: 10px
		gap: 10px
		margin-bottom: 10px
		.gs-main-button
			width: 100px

.comment-modal
	.ant-modal-body
		max-height: 420px
		overflow-y: auto
		.textarea-buttons-container
			display: flex
			gap: 2px
			.ant-form-item
				margin-bottom: 5px !important
				margin-top: 10px
			.gs-secondary-button
				span
					color: #0065B3 !important
			button
				border: none
				font-size: 17px
