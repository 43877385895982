.badge
	padding: 9px 0px
	border-radius: 6px
	color: #717171
	background: #FFFFFF

	.badge-content
		display: flex
		gap: 14px
		box-sizing: border-box
		align-items: center
		justify-content: center
		width: 100%

		svg
			width: 27px
			height: 27px

	.badge-text

		.badge-title
			font-size: 14px
			text-overflow: ellipsis
			white-space: nowrap
			overflow: hidden

		.badge-subtitle
			height: 22px
			line-height: 22px
			color: #4F9ACF
			font-weight: bold
			font-size: 22px
