.actions-content
	display: flex
	align-items: center
	gap: 20px
	padding: 24px
	& > button
		height: 35px
		padding: 0 20px
	& > span
		max-width: 300px
	.actions-box
		display: flex
		gap: 20px
		width: 50%
		align-items: center
		&:nth-child(2)
			justify-content: end

.actions-content-transfer
	display: flex
	align-items: center
	justify-content: start
	gap: 20px
	padding: 0 24px 24px 0
	& > button
		height: 35px
		padding: 0 20px
	& > span
		max-width: 300px

.collaborator-container

	.infos-resource

		.resource-name
			color: #0065B3
.container-resource
	border-top: 1px solid #D7D7D7
	border-bottom: 1px solid #D7D7D7
	.infos-resource
		display: flex
		justify-content: space-between
		padding: 20px 0
		margin-bottom: 10px
		gap: 20px
		.collaborator-name
			div
				display: flex
				align-items: center
				gap: 7px
				h3
					color: #0065B3
				svg
					font-size: 18px
		.collaborator-infos
			width: calc(100% - 230px)
			display: flex
			gap: 40px
			padding-bottom: 10px

			.ant-carousel
				width: calc(100% - 217px) !important
				margin-top: 5px
				.slick-track
					height: 69px
				.carousel-container-info
					display: flex !important
					justify-content: space-around
					.slick-slide>div
						&>div
							max-width: 20%

				.slick-initialized
					h4, .gs-tag
						overflow: hidden
						text-overflow: ellipsis
						white-space: nowrap
						width: 100%
						display: block

.collaborator-search
	display: flex
	align-items: center
	padding: 0 !important
	p
		margin: 0
		padding: 0 15px
	svg
		height: 100%
		border-left: 1px solid #ccc
		margin-right: 8px
		padding-left: 8px
		width: 24px
.collaborator-search-overlay
	width: 20%
	.ant-popover-inner
		background: #333333
	.popover-detail
		background: #333333
		color: #fff
		.title-content
			font-weight: 100
		.text-content
			font-weight: 500
.collaborator-search-container
	width: 400px
	.ant-select
		width: 100%
	.collaborator-search-input-container
		padding: 15px
		background-color: #f1f2f3
		border-top-left-radius: 9px
		border-top-right-radius: 9px
	.collaborator-select-input-container
		border-bottom: 1px solid #d7d7d7
		padding: 15px 15px 25px
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		gap: 10px
		.ant-form-item
			width: 100%
	.ant-radio-group
		padding: 15px 15px 0
	.collaborator-search-footer
		display: flex
		align-items: center
		justify-content: end
		gap: 15px
		padding: 10px 20px
		border-top: 1px solid #d7d7d7
		margin-top: 20px
		.gs-main-button
			padding: 0 40px
	.collaborator-list-search
		padding: 10px 0 0 0
		.ant-space-vertical
			max-height: 300px
			overflow-y: auto
			overflow-x: hidden
		.ant-space-item
			.ant-radio-wrapper
				width: 100%
				.ant-radio
					width: 16px
				span:not(.ant-radio)
					width: 100%

		h4
			display: flex
			justify-content: center

.human-resources-first-access
	background: #fff
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	padding: 40px 0
	height: 100%
	min-height: 435px
	h2
		margin: 20px 0 0
	p
		margin: 5px 0 20px

.hired-table-container
	background: #fff
	.hired-header
		display: flex
		h3
			margin: 0
			min-width: 200px
		.hired-budget
			display: flex
			align-items: center
			gap: 8px
			width: -webkit-fill-available
			justify-content: space-between
			padding: 0 20px 0 0
	h3
		padding: 10px 20px
		font-size: 20px
		font-weight: 400
		span
			color: #0065b3
			font-weight: 500
	.hired-table
		margin: auto
		padding: 0 20px 50px
		background: #fff
		.buttons-col
			justify-content: end
		.allocated-icon-button
			align-self: center
		.ant-table-thead > tr > th
			background-color: #f5f5f5
		.hired-table-row
			cursor: pointer
		td
			padding: 12px !important
			color: #717171
			font-weight: 400

			svg
				color: #0065B3

		.collaborator-column
			color: #424242
			font-weight: 500

.gs-modal
	.collaborators-registration-form
		overflow-y: auto
		overflow-x: hidden
		padding-right: 14px
		.flex-center
			display: flex
			align-items: center
			.ant-row
				margin: 0
		.gs-add-to-list-button
			font-size: 30px
			color: #0065B3
		.first-container-form
			.gs-date-picker-input
				width: 100%
			.is-prior-hiring
				display: flex
				align-items: center
				.ant-form-item
					margin: 0
			.ant-col-6
				display: flex
				align-items: flex-end
				width: 100%

				.ant-form-item
					width: 100%

		.ant-btn-icon-only
			border: none

		.ant-input-number,
		.ant-input-number-affix-wrapper
			width: 95%

	.cost-category-list-content

		.table-collaboratos-col
			text-overflow: ellipsis
			overflow: hidden
			display: block !important
			white-space: nowrap

.overlay-operation-bar-hr

	.ant-popover-inner-content
		padding: 0 !important

.allocation-modal-hr
	.row-allocation-table
		border: 1px solid #D7D7D7
		border-radius: 4px
		background-color: #FCFCFC
	.ant-modal-body
		align-items: inherit !important
		padding: 0
		margin: 0 16px 0 0
		aside
			border-right: 1px solid #E2E2E2
			margin-right: 16px
			padding: 7px 7px 7px 0
	.ant-layout
		flex-direction: row
		background: none
		padding: 0 10px
		.ant-layout-content
			padding: 15px
	.ant-picker
		background: #fff
		border: 1px solid #d9d9d9
		border-radius: 2px
		width: 100%
	.ant-menu-root
		border: none
		overflow-y: auto
		max-height: 550px
		padding: 0 7px
		li
			padding: 0 8px
	.ant-table-tbody > tr.ant-table-row:hover > td
		background: none !important
	.ant-table-wrapper
		width: 100%
	.ant-row
		margin-bottom: 8px
		.ant-col
			display: flex
			.ant-form-item
				margin: 0
				width: 100%
				.ant-form-item-control-input
					width: 100%
		.ant-input-number
			width: 100%
	.add-button-col
		display: flex
	.gs-secondary-button
		align-self: end
		margin-bottom: 8px
	.allocation-table-row
		border-top: 1px solid #E2E2E2
	.ant-menu-item-selected
		background: #E8F2F9
		color: unset
	.resources-list-allocation
		.button-replicate-allocations
			opacity: 0
		&:hover
			.button-replicate-allocations
				opacity: 1
