.item-card-container
	display: flex
	align-items: center
	justify-content: center
	width: calc(20% - 10px)
	height: 51px
	padding: 0 15px
	margin: 10px 10px 0 0
	border: 1px solid
	border-radius: 8px
	transition: all 0.3s

	&:not(:hover)
		border-color: #D7D7D7
		background: #FCFCFC 

	&:hover
		border-color: #4F9ACF
		background: rgba(79, 154, 207, 0.07)

	h3
		color: #004461
		margin: 0
		font-weight: normal
		text-align: center

	svg
		width: 25px
		height: 25px
		margin-right: 10px
		font-size: 30px
		color: #004461
