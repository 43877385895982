.terminate-modal
  .radio-pair-container
    margin-bottom: 16px
    .ant-radio-group
      display: flex !important
      flex-direction: row !important

  .radio-pair-container .ant-radio-wrapper
    margin-right: 8px

  .radio-pair-container .ant-radio-wrapper + span
    margin-right: 16px

  form
    width: 100%
    .fields-container
      width: 100%
      display: flex
      gap: 1.5rem

      .input-content
        margin-right: 0
        margin-bottom: 5px
        width: 21.3125rem

      .input-indexer
        width: 39.125rem

      .ant-form-item
        display: block !important

      .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before
        display: none

      .ant-form-item-label > label
        height: auto !important
        color: #5C5C5C
        font-size: 12px
        line-height: 16px

      .ant-picker
        border-radius: 2px
        width: 100%
        border: 1px solid #d9d9d9
        background-color: white
        input
          font-weight: 400
