.accounting-ledger-header
    display: flex
    justify-content: space-between
    height: 80px
    margin: 0px auto 16px
    padding: 0px 16px
    width: 100%

.accounting-ledger-container-section
    height: calc( 100vh - 120px )
    width: 100%
    background-color: #F1F2F3
    .content-table
        width: 95%
        margin-left: 2%
        .nowrap-cell
            padding: 8px 13px
            width: auto !important
            white-space: nowrap !important
            text-align: left !important
        .alignment-date-cell
            text-align: center !important
        .alignment-value-cell
            text-align: right !important
        #accounting-ledger-table .ant-table-summary table
            table-layout: auto !important

.accounting-ledger-container-filter-operation
    background-color: #F1F2F3
    margin-top: 10px
.operation-accounting-ledger-content-table
    gap: 10px
    display: flex
    align-items: center
    justify-content: flex-end
    background-color: #F1F2F3
    margin-top: 10px
    margin-right: 50px
    .accounting-ledger-multiple-search
        display: flex
        justify-content: flex-end
        margin-bottom: 10px
        margin-top: 10px
    .select-search
        width: 300px
    .search-field
        width: 225px
    #group
        color: #000
        font-size: 17px
    #export
        color: #000

.accounting-ledger-no-data
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 70%

.button-expandable-tree
    width: 25px
    height: 25px
    float: left
    position: relative
    cursor: pointer
    user-select: none
    text-align: center
    font-size: 15pt
    color: #717171
    line-height: 25px
