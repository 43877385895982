.filters-container-style
    display: flex
    align-items: center
    justify-content: space-between
    margin: 24px 24px 0 24px
    .ant-carousel
        .gs-carousel
            .slick-arrow.slick-next
                z-index: 2 !important
            .slick-arrow.slick-prev
                z-index: 2 !important
    .filter-year-container
        margin-right: 8px
        padding-right: 16px
        border-right: 2px solid #ccc
        .filter-name
            color: #072D49
            font-weight: 500
            margin: 0 0 1px 0
        .ant-picker
            background-color: #fff
            padding: 0
            width: auto
