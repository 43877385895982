#float-menu-container
	position: absolute
	left: 0
	#float-menu-search
		width: 100%
		height: 55px
		position: fixed
	#float-menu-header
		width: 100%
		height: 45px
		display: flex
		align-items: center
		justify-content: flex-start
		background: #285A7E
		position: fixed
		top: 0
		z-index: 1

#float-menu-budget
	position: absolute
	z-index: 100
	right: 0
	width: 100%
	min-height: calc(100% - 200px)
	user-select: none
	background: #fff
	border: 1px solid #D7D7D7
	border-top-left-radius: 3px
	border-bottom-left-radius: 3px
	border-right: none
	padding-bottom: 24px
	#float-menu-search
		padding: 10px
		> input
			margin: 0 auto

#float-menu-container
	min-height: 100%
	user-select: none
	background: #42424212
	border: 1px solid #D7D7D7
	border-right: none
	border-bottom: none
	#float-menu-search
		margin-top: 44px
		> input
			margin: 10px auto
	#float-menu-content
		margin-top: 88px

#float-menu-container,
#float-menu-budget
	#float-menu-search
		width: 100%
		height: 55px
		background-color: #f2f2f2
		z-index: 1

		> input
			width: 95%
			height: 35px
			background-color: #fff
			border: none
			border-radius: 20px
			display: block

	#float-menu-header
		width: 100%
		height: 45px
		display: flex
		align-items: center
		justify-content: flex-start
		background: #285A7E

		&> button
			height: 100%
			width: 40px
			margin: 0
			padding: 0
			border: none

		p
			color: #fff
			margin: 10px

		svg
			color: #004461
			background: #BDD7EA
			height: 100%
			padding: 0 10px
			width: 100%
			margin-right: 15px

#float-menu-content
	height: auto
	min-height: calc(100% - 88px)
	background: transparent
	color: #424242

	.ant-menu-submenu

		&:not(:first-child)
			margin-top: 15px

		> .ant-menu-submenu-title
			width: 90%

		.ant-menu-submenu-title
			height: 20px
			margin: 20px auto 10px
			padding: 0
			font-size: 12px
			font-weight: bold
			color: #0065B3
			border-bottom: 1px solid #0065B3
			background: transparent

		.ant-menu-inline
			background: transparent

		.ant-menu-submenu

			> .ant-menu-submenu-title
				width: calc(100% - 40px)
				margin-left: 25px
				padding-right: 35px

	.ant-menu-item
		&:hover
			background: #42424212

	a
		color: #424242

		&:hover
			color: #424242
