#management-account-container
    display: flex
    flex-direction: column
    height: calc(100vh - 118px)
    .split
        height: 100%
        background: #fff
        padding: 0 24px
        .sider-content
            overflow: auto
            padding: 9px
            background: #FCFCFC
            border: 1px solid #D7D7D7

            p
                margin: 0

            svg
                color: #0065B3
                font-size: 1rem
        .empty-list-container
            display: flex
            height: 100%
        main
            table
                width: 100%
                tr
                    td
                        border: 1px solid #DFDFDF
                        padding: 3px 10px
                        width: 50%
                        .ant-form-item
                            margin: 0
                            height: 30px !important
                            input,
                            .ant-select-selector
                                border: none
                        .ant-form-item-has-error
                            height: 60px !important
                    .grey-background
                        background-color: #F2F2F2
            footer
                margin-top: 15px

    .tree-level-warning-container
        display: flex
        flex-direction: column
        justify-content: center
        flex: 1

    .site-tree-search-value
        color: #84a6c0

    .sider-content
        display: flex
        flex-direction: column
        gap: 0.2rem
        height: 100%
        width: 100%
        min-width: 250px

        .tree-title-content
            display: flex
            justify-content: space-between
            p
                margin: 0
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis

            & > div
                display: flex
                align-items: center

                & > svg
                    color: #0065B3

                button
                    border: none
                    background: transparent
                    height: 24px
                    align-items: center
                    justify-content: center
                    display: flex
                    opacity: 0

                    svg
                        color: #0065B3

        .tree-title-content:hover
            button
                opacity: 1

        .ant-input-affix-wrapper
            margin-bottom: 8px
            background: #E8F2F9
            border: 0
            border-radius: 4px

        svg
            font-size: 1rem !important

        .ant-tree-icon-hide
            height: 100%

            .ant-tree-list
                height: 100%

                .ant-tree-checkbox
                    margin-top: 2px
                    margin-left: 5px

                .ant-tree-list-holder
                    height: 100%

                    & > div
                        overflow-x: hidden
                        height: 100%

        .ant-tree-list-holder-inner
            height: 100%
            padding-bottom: 10px

            .ant-tree-treenode
                width: -webkit-fill-available

                .ant-tree-node-content-wrapper
                    width: 100%
                    min-width: 0

        .ant-tree-treenode,
        .ant-tree-list-holder-inner
            border: none
