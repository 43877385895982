@import '../variable'

.ant-btn
	&.ant-btn-default
		border-radius: 5px
	&.ant-btn-primary
		width: 8rem
		border-radius: 5px
		border: none
		// background: $font-green-color

		// &:not(:disabled):hover
		// 	background: $font-green-semidark-color

		&:disabled
			cursor: not-allowed
			background: #686666
			color: #fff

.gs-main-button, .gs-main-button
	width: auto
	height: 35px
	background: #0065B3
	border: none
	border-radius: 6px
	color: #fff
	display: flex
	align-items: center
	justify-content: space-evenly
	transition: all 0.3s
	&:not(:disabled):focus,
	&:not(:disabled):active
		background-color: #0065B3
		color: #fff
	&:not(:disabled):hover
		cursor: pointer
		background-color: #045594
		color: #fff
	svg
		color: #fff
		margin: 0 6px 0 0
		font-size: 18px
	&:disabled
		cursor: not-allowed

.gs-secondary-button
	width: auto
	height: 35px
	margin-left: 10px
	background: #fff
	border: 1px solid #cccccc
	border-radius: 6px
	color: #0D2F48
	display: flex
	align-items: center
	justify-content: space-evenly
	cursor: pointer
	transition: all 0.3s
	svg
		margin: 0 6px 0 0
		font-size: 18px

	&:not(:disabled):hover,&:not(:disabled):focus,&:not(:disabled):active
		border-color: #4F9ACF !important
		color: #4F9ACF !important
		svg
			color: #4F9ACF !important

.delete-button
	background: #FF4D4F !important
	cursor: pointer

.edit-icon
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)
.edit-icon:hover
	color: #1890ff
	cursor: pointer

.delete-icon
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)
.delete-icon:hover
	color: #FF4D4F
	cursor: pointer

.gs-plus-button
	color: #0065b3
	width: 20px
	height: 19px
	svg
		font-size: 26px
	&:hover
		color: #045594

.gs-return-button
	height: 24px
	padding: 6px 14px
	margin-left: 4px
	width: auto
	background: #fff
	border: 1px solid #0D2F48
	border-radius: 6px
	color: #0D2F48
	display: flex
	align-items: center
	justify-content: space-evenly
	cursor: pointer
	svg
		margin: 0 6px 0 0
		font-size: 14px
		transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)

	&:not(:disabled):hover,&:not(:disabled):focus,&:not(:disabled):active
		border-color: #4F9ACF !important
		color: #4F9ACF !important
		svg
			color: #4F9ACF !important
