#detail-modal-revenue
    .detail-modal-revenue-tables
        gap: 20px

        .ant-table-container table > thead > tr:first-child th:first-child
            background: #DBDBDB

        .row-dragging
            background: #fafafa !important
            border: 1px solid #ccc !important

            td
                padding: 16px !important

            .drag-visible
                visibility: visible !important
