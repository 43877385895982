.distribution-form-container
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	.ant-input-number
		width: 100%
	.submit-button
		display: flex
		align-items: center
	.ant-select-selector
		padding: 0
		.ant-select-selection-overflow
			overflow-x: auto
			height: 30px
	.ant-input-number-affix-wrapper
		width: 100%
	#distribution-values-form
		width: 100%
	.distribution-form-inputs-content
		display: flex
		align-items: center
		justify-content: start
		flex-wrap: wrap
		column-gap: 20px

.distribution-list-container
	width: 100%
	.all-selected
		color: #0065B3
		font-weight: 500
	.distribution-list-empty
		margin: 40px auto
		display: flex
		flex-direction: column
		align-items: center
		row-gap: 5px
	.distribution-list-header
		display: grid
		grid-template-columns: repeat(6, 2fr) repeat(3, 1fr) repeat(2, 0.4fr)
		width: 100%
	.cost-category-list-content
		.result-column
			justify-content: center !important
	.list-of-added-items .cost-category-list-content .ant-row
		padding: 0
		border: 1px solid #D7D7D7
		background: #FCFCFC
		.ant-col
			justify-content: flex-start
			height: 32px

#dist-cascader-form
	width: 100%
	margin-top: -4px
	padding: 8px 12px
.dist-form-cascader-dropdown
	.ant-cascader-menus
		margin-top: 4px
		.ant-cascader-menu
			padding: 0
			.ant-cascader-checkbox-checked span
				background-color: #4F9ACF
				border-color: #4F9ACF
