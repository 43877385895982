@import "components/variable"

.popover-integrations
	form
		display: flex
		flex-direction: column
		height: 100%
		.ant-form-item,.ant-select
			width: 100%
	.interface-integration-content
		height: 150px
		width: 250px
	.configuration-setup-footer
		justify-content: end

.popover-scenario-setup
	form
		display: flex
		flex-direction: column
		justify-content: space-between
		height: 100%
		.ant-form-item,.ant-select
			width: 100%
	.scenario-setup-content
		height: 150px
		width: 250px
	.configuration-setup-footer
		justify-content: end
.replicate-scenario-modal
	.ant-modal-footer
		button
			display: unset
	.label-container
		padding: 5px 0px
.replicate-scenario-month
	background: #fff
	border: 1px solid #d9d9d9
	width: auto
.budget-integrations-page
	background: #FCFCFC
	.scenario-setup-button
		display: flex
		gap: 15px
		button
			display: flex
			align-items: center
			justify-content: center
			gap: 8px
			color: $font-icon-color
			p
				margin: 0
	.error-column
		width: max-content
		p
			margin: 0
	.header-content
		.disabled-button
			cursor: not-allowed !important
			color: rgba(0, 0, 0, 0.25) !important
			border-color: #d9d9d9 !important
			background: #f5f5f5 !important
			text-shadow: none !important
			box-shadow: none !important
		.page-title-content
			background: #FCFCFC
			flex-direction: column
			align-items: start
			height: auto !important
			.top-buttons
				display: flex
				align-items: center

			.gs-top-buttons
				background: #FCFCFC
				width: auto
				.top-buttons-left-content
					margin: 0
				.top-buttons-right-content
					margin: 15px 6px
				.gs-secondary-button
					width: auto
					height: 35px
					margin-left: 10px
					background: #fff
					border: 1px solid #0D2F48
					border-radius: 6px
					color: #0D2F48
					display: flex
					align-items: center
					justify-content: space-evenly
					cursor: pointer
					svg
						margin: 0 6px 0 0
						font-size: 18px

					&:not(:disabled):hover,&:not(:disabled):focus,&:not(:disabled):active
						border-color: #4F9ACF !important
						color: #4F9ACF !important
						svg
							color: #4F9ACF !important
		.ant-col-16
			justify-content: end
			display: flex
			.header-right
				display: flex
				flex-direction: column
				gap: 5px
				.date-range-button
					justify-content: end
				button
					padding: 0
				input
					width: 250px
	.collapse-content
		padding: 20px
		.status-integration-content
			display: flex
			align-items: center
			gap: 5px
			svg
				cursor: pointer
				font-size: 18px
				color: #0065B3
		.running-processes
			display: flex
			justify-content: end
			height: fit-content
		.ant-row
			width: 100%
		p
			margin: 0
			width: max-content
		.ant-collapse
			border: none
			width: 100%
			gap: 20px
			display: flex
			flex-direction: column
			.ant-collapse-item
				border: 1px solid #D7D7D7
				border-radius: 6px
				.ant-collapse-header
					height: 46px
					svg
						font-size: 21px
					.ant-row
						svg
							color: $font-icon-color
				.ant-collapse-content
					.ant-collapse-content-box
						padding: 0
						.gs-table
							width: 100%
							.ant-table-cell
								border-bottom: none
							.error-column
								white-space: nowrap
								overflow: hidden
								text-overflow: ellipsis

.gs-modal-data-request
	.data-request-form
		.div-form
			display: flex
			gap: 50px
		.div-button
			display: flex
			justify-content: flex-end
			.button-add
				color: 	#0D2F48
				display: flex
				gap: 5px
				align-items: center
				margin-bottom: 20px

.table-data-request-update
