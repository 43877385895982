.ant-notification-notice
	border-radius: 0
	padding: 12px 20px 15px 10px

.ant-notification-notice-with-icon
	#alert-message-content
		display: flex
		flex-direction: column
		align-items: flex-start
		justify-content: center
		margin-bottom: 0

	.ant-notification-notice-message
		font-size: 16px
		font-weight: 600
		margin-bottom: 0 !important

// SUCCESS
.ant-notification-notice-success
	border-left: 3px solid #39AA74
	background: #D3F1E3

	.ant-notification-notice-icon svg
		color: #39AA74

	.ant-notification-notice-message
		color: #333333
		margin-left: 40px

	.ant-notification-notice-description
		color: #333333
		margin-left: 40px

// WARNING
.ant-notification-notice-warning
	border-left: 3px solid #FCB03E
	background: #F8FDDA

	.ant-notification-notice-icon svg
		color: #FCB03E

	.ant-notification-notice-message
		color: #333333
		margin-left: 40px

	.ant-notification-notice-description
		color: #333333
		margin-left: 40px

.ant-notification-notice-error
	border-left: 3px solid #FF4D4F
	background: #FFF1F0

	.ant-notification-notice-icon svg
		color: #FF4D4F

	.ant-notification-notice-message
		color: #333333
		margin-left: 40px

	.ant-notification-notice-description
		color: #333333
		margin-left: 40px

.ant-notification-notice-info
	border-left: 3px solid #1890FF
	background: #E6F7FF

	.ant-notification-notice-icon svg
		color: #1890FF

	.ant-notification-notice-message
		color: #333333
		margin-left: 40px

	.ant-notification-notice-description
		color: #333333
		margin-left: 40px
