.gs-font-color-primary
	color: $font-primary-color !important

.gs-font-color-secondary
	color: $font-secondary-color !important

.gs-font-color-green
	color: $font-green-color !important

.gs-font-color-red
	color: $font-red-color !important

.gs-font-color-blue
	color: $font-blue-color !important

.gs-font-bold
	font-weight: bold !important