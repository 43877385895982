@import "components/variable"

#accounting-balances-container
    padding: 15px 24px
    background: #fff
    height: calc(100vh - 222px)
    .page-content
        height: 100%
        .content-adjustment
            display: flex
            flex-direction: column
            height: 100%
            width: 100%
            margin-right: -134rem
            padding: "0 1rem"

        .filter-container
            display: flex
            justify-content: space-between
            width: 100%
        & > section
            height: 100%
            background: #fff
            .split
                height: 100%
                background: #fff
                .sider-content
                    overflow: auto
                    padding: 9px
                    background: #FCFCFC
                    border: 1px solid #D7D7D7
                    p
                        margin: 0
                    svg
                        color: #0065B3
                        font-size: 1rem
            .content-container
                min-width: 110vh
                .content-adjustment
                    height: 100%
                    width: 100%
                    margin-right: -134rem
                    &> main
                        height: 100%
                        padding: 9px
                        .content-tables
                            .all-items-checked-info
                                min-height: 44px
                                max-height: 60px
                            & > div
                                & > div.selected-all-table
                                    height: calc(100% - 120px) !important
                                & > div
                                    height: calc(100% - 50px)
                                    .ant-spin-nested-loading,
                                    .ant-spin-container
                                        .ant-table
                                            height: calc(100% - 64px)
                                            overflow: auto
                                            tr > th
                                            min-width: 50px
                                .gs-main-button, .gs-secondary-button
                                    align-self: end
                .tree-level-warning-container
                    height: 100%
                    .empty-list-container
                        display: flex
                        height: 100%

        .site-tree-search-value
            color: #84a6c0

        .gs-table
            .ant-table-container
                display: flex
                flex-direction: column
                font-size: 13px
                .ant-table-thead th
                    background: #44759A
                    color: #fff
                    padding: 5px
                    &:hover
                        background: #44759A
                .ant-table-tbody 
                    tr > td.realized-column
                            background: rgba(46, 182, 104, 0.12)
                            color: #111412
                    td
                        padding: 5px
                        min-width: 90px
                        width: auto

                        &:not(:first-child) 
                            text-align: center

        .sider-content
            display: flex
            flex-direction: column
            gap: 0.2rem
            height: 100%
            width: 100%
            .sider-content
                display: flex
                flex-direction: column
                align-items: center
                width: 100%
                margin-top: 40px
            .ant-checkbox-group
                display: flex
                flex-direction: column
            .tree-title-content
                display: flex
                justify-content: space-between
                p
                    margin: 0
                    white-space: nowrap
                    overflow: hidden
                    text-overflow: ellipsis
                & > div
                    display: flex
                    align-items: center
                    & > svg
                        color: #0065B3
                    button
                        border: none
                        background: transparent
                        height: 24px
                        align-items: center
                        justify-content: center
                        display: flex
                        opacity: 0
                        svg
                            color: #0065B3
            .tree-title-content:hover
                button
                    opacity: 1
            .ant-input-affix-wrapper
                margin-bottom: 8px
                background: #E8F2F9
                border: 0
                border-radius: 4px
            svg
                font-size: 1rem !important
            .ant-tree-icon-hide
                height: 100%
                .ant-tree-list
                    height: 100%
                    .ant-tree-checkbox
                        margin-top: 2px
                        margin-left: 5px
                    .ant-tree-list-holder
                        height: 100%
                        & > div
                            overflow-x: hidden
                            height: 100%
            .ant-tree-list-holder-inner
                height: 100%
                padding-bottom: 10px
                .ant-tree-treenode
                    width: -webkit-fill-available
                    .ant-tree-node-content-wrapper
                        width: 100%
                        min-width: 0
            .ant-tree-treenode,
            .ant-tree-list-holder-inner
                border: none
            .ant-layout-content
                margin-right: -65rem
                width: 100%

            .ant-layout-content
                margin-right: -65rem
                width: 100%

        .period-filter-card
            padding: "0.5rem 0"

        .accomplished-filter-card
            .filters-container
                marginTop: 0
                .filters-content
                    width: "100%"
                    div
                        width: 120px

.ant-layout-header
    display: flex
    justify-content: space-between
    background-color: #fff
