.additive-modal
    form
        width: 100%

        .input-content
            margin-right: 0
            margin-bottom: 5px
            width: 100%

        .input-indexer
            width: 39.125rem

        .ant-input-number
            width: 100%
            height: 30px
            padding: 0 11px

        .ant-form-item
            display: block !important

        .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before
            display: none

        .ant-form-item-label > label
            height: auto !important
            color: #5C5C5C
            font-size: 12px
            line-height: 16px

        .ant-picker
            border-radius: 2px
            width: 100%
            border: 1px solid #d9d9d9
            background-color: white
            input
                font-weight: 400