.contract-representativeness-widget

	.badge
		background: rgba(234, 243, 249, 0.52)
		border: none

		.loading
			svg
				-webkit-animation: loadingCircle 1s infinite linear
						animation: loadingCircle 1s infinite linear

	.account_balance

		svg
			color: #4F9ACF

	.entry

		svg
			color: #2ECC71

		.badge-subtitle
			color: #2ECC71 !important

	.exits

		svg
			color: #FF0C0C

		.badge-subtitle
			color: #FF0C0C !important
