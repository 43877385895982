.step-modal
  .ant-modal-body
    padding: 0

    .step-wrapper
      display: flex

      .step-image
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        gap: 2.5rem
        padding: 0 47px
        .custom-steps
          display: flex
          align-items: center
          .custom-step
            width: 7px
            height: 7px
            border-radius: 50%
            background-color: #C4C4C4
            margin-right: 7px
            &.active
              width: 7px
              height: 7px
              background-color: #4F9ACF

      .step-content
        height: 400px
        width: 646px
        background-color: #F1F2F3
        padding: 1.875rem
        .step-title
          font-weight: 700
          font-size: 20px
          line-height: 25px
          color: #424242
        .step-subtitle
          font-weight: 400
          font-size: 18px
          line-height: 22.5px
          color: #424242

        .pass-inputs // step-1
          display: flex
          flex-direction: column
          gap: 31px
          margin-top: 41px

        .lookup-wrapper
          display: flex
          gap: 20px
          flex-direction: column

          .label
            color: #5C5C5C
            font-weight: 400
            font-size: 12px
            line-height: 16px

          .ant-row > .ant-col > .ant-select
            margin-bottom: 1.5rem

        .environment-btns // step-2
          display: flex
          flex-direction: column
          gap: 24px

          .environment-btn
            display: flex
            align-items: center
            cursor: pointer
            background-color: #FCFCFC
            width: 41rem
            height: 4rem

            &:hover
              background-color: #f8f8f8

            .icon-wrapper
              display: flex
              align-content: center
              justify-content: center
              align-items: center
              width: 89px
              height: 64px
              border: solid 0.5px #D7D7D7
              border-radius: 5px 0 0 5px

              &.border-active
                border: solid 0.5px #4F9ACF
                border-right: solid 0.5px #4F9ACF
                border-radius: 5px 0 0 5px

            .btn-label
              height: 64px
              font-weight: 600
              font-size: 18px
              line-height: 24.51px
              color: #424242
              cursor: pointer
              border: solid 0.5px #D7D7D7
              border-radius: 0 5px 5px 0
              border-left: none
              width: 100%
              padding: 20px

              &.border-active
                border: solid 0.5px #4F9ACF
                border-left: none
                border-radius: 0 5px 5px 0

.step-modal-wfn
  .step-wrapper
    display: flex
    height: 500px

    .step-image
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      gap: 2.5rem
      padding: 0 47px
      .custom-steps
        display: flex
        align-items: center
        .custom-step
          width: 7px
          height: 7px
          border-radius: 50%
          background-color: #C4C4C4
          margin-right: 7px
          &.active
            width: 7px
            height: 7px
            background-color: #4F9ACF

    .step-content
      height: 500px
      width: 646px
      background-color: #F1F2F3
      padding: 1.875rem
      display: flex
      flex-direction: column
      justify-content: flex-start
      height: 100%
      .step-title
        font-weight: 700
        font-size: 20px
        line-height: 25px
        color: #424242
      .step-subtitle
        font-weight: 400
        font-size: 18px
        line-height: 22.5px
        color: #424242
      button
        margin-top: auto
        align-self: flex-end

      .pass-inputs // step-1
        display: flex
        flex-direction: column
        gap: 31px
        margin-top: 41px

      .lookup-wrapper
        display: flex
        gap: 20px
        flex-direction: column

        .label
          color: #5C5C5C
          font-weight: 400
          font-size: 12px
          line-height: 16px

        .ant-row > .ant-col > .ant-select
          margin-bottom: 1.5rem

      .environment-btns // step-2
        display: flex
        flex-direction: column
        gap: 24px

        .environment-btn
          display: flex
          align-items: center
          cursor: pointer
          background-color: #FCFCFC
          width: 41rem
          height: 4rem

          &:hover
            background-color: #f8f8f8

          .icon-wrapper
            display: flex
            align-content: center
            justify-content: center
            align-items: center
            width: 89px
            height: 64px
            border: solid 0.5px #D7D7D7
            border-radius: 5px 0 0 5px

            &.border-active
              border: solid 0.5px #4F9ACF
              border-right: solid 0.5px #4F9ACF
              border-radius: 5px 0 0 5px

          .btn-label
            height: 64px
            font-weight: 600
            font-size: 18px
            line-height: 24.51px
            color: #424242
            cursor: pointer
            border: solid 0.5px #D7D7D7
            border-radius: 0 5px 5px 0
            border-left: none
            width: 100%
            padding: 20px

            &.border-active
              border: solid 0.5px #4F9ACF
              border-left: none
              border-radius: 0 5px 5px 0
