#undo-title-and-planning
    display: flex
    align-itens: center
    align-content: center
    flex-direction: row
    justify-content: space-between
    align-items: center
    .undo-projection-title
        margin: 0 1.5rem
    .tag-wrapper
        display: flex
        gap: 8px
        flex-wrap: wrap
        justify-content: flex-end

.filter-period-undo
    background-color: #FCFCFC
    border: 1px solid #D7D7D7
    margin-left: 24px
    margin-right: 24px
    width: calc(100% - 48px)
    height: 0px
    border-radius: 6px
    display: flex
    flex-direction: row
    align-items: center
    padding: 25px
    color: #424242
    font-size: 12px
    font-weight: 400

    .filter-period-content
        display: flex
        flex-direction: row
        align-items: center

        .select-start-end
            width: 17px
            height: 0px
            margin-top: 16px
            margin-left: -16px
            margin-right: 8px
            border: 1px solid #D7D7D7

.date-picker-undo
    display: flex
    flex-direction: column
    justify-content: space-around
    align-items: left
    width: 150px
    margin-right: 25px

    .text-date-picker
        font-size: 10px
        margin-bottom: 2px
        font-weight: 600

.filter-period-undo
    .ant-picker
        border-radius: 2px
        width: 100%
        border: 1px solid #d9d9d9
        background-color: white
        input
            font-weight: 400

.table-container-undo
    margin-left: 24px
    margin-right: 24px
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table
        border-top: none

.component-filter-undo
    display: flex
    align-items: center
    float: right
    gap: 0.5rem
    border-radius: 4px
    background-color: #F1F2F3
    margin-right: 1rem

.content-filter-undo
    display: flex
    align-items: center
    gap: 0.5rem
    color: #5c5c5c
    font-size: 12px

.content-filter-undo-counter
    display: flex
    align-items: center
    justify-content: center
    font-size: 12px
    font-weight: 600
    color: #0065B3
    background-color: #D9D9D9
    border-radius: 50%
    width: 25px
    height: 25px

.remove-component-icon-undo
    color: #5C5C5C
    display: flex
    align-items: center
    justify-content: center

.space-filters-undo
    margin-right: auto
    display: flex
    flex-direction: row

    .remove-all-undo
        font-size: 12px
        font-weight: 600

#undo-main
    .undo-loading-wrapper
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        z-index: 9999
        width: 100%
        height: 100%
        backdrop-filter: blur(1px)

    .grid-info
        margin: 1rem 1.5rem 0
        display: flex
        justify-content: space-between

    .grid-length
        margin: 0
        font-size: 12px
        font-weight: 400
        line-height: 16px
        color: #0065B3
        align-self: center
