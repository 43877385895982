.environment-tag
  display: flex
  align-items: center
  float: right
  gap: 0.5rem
  border-radius: 4px
  padding: 4px 8px
  background-color: #F1F2F3
  margin-right: 1rem
  cursor: pointer
  .globe-icon
    color: #5C5C5C
    display: flex
    align-items: center
    justify-content: center

  p
    color: #5C5C5C
    font-weight: 400
    font-size: 12px
    line-height: 16px
    white-space: nowrap
    margin: 0

.environment-tag:hover
  background-color: #D7D7D7

.change-environment-modal
  .change-environment-modal-heading
    font-weight: 400
    font-size: 18px
    line-height: 22.5px
    color: #424242
    
  .btn-wrapper
    display: flex
    flex-direction: column
    gap: 24px

    .change-environment-modal-btn
      display: flex
      align-items: center
      cursor: pointer
      background-color: #FCFCFC

      width: 41rem
      height: 4rem
      font-weight: 600
      font-size: 18px
      line-height: 24.51px
      color: #424242
      .btn-label
        height: 64px
        cursor: pointer
        border: solid 0.5px #D7D7D7
        border-radius: 0 5px 5px 0
        border-left: none
        padding: 20px
        width: 100%

      .icon-wrapper
        display: flex
        align-content: center
        justify-content: center
        align-items: center
        width: 89px
        height: 64px
        border: solid 0.5px #D7D7D7
        border-radius: 5px 0 0 5px
        svg
          color: #0065B3

    .change-environment-modal-btn:hover
      background-color: #f8f8f8
