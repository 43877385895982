.subheader-main
	height: 40px
	display: flex
	justify-content: space-between
	padding: 8px 16px
	color: #004461
	align-items: center
	background-color: #F1F2F3

	.environment-tag
		margin: 0 1rem 0 auto

	>button
		border: none
		background: transparent
		cursor: pointer
		padding: 1px 0
		height: 40px
		&:hover
			border-bottom: 1px solid #004d7b

	>nav
		background: #fcfcfc
		border-radius: 12px
		padding: 2px 20px
		border-radius: 30px

		li
			a
				color: #424242
				svg
					margin: 0 5px 0 0

			&:last-child a
				color: #4F9ACF

	.screen-filter-main
		display: inline-flex
		font-size: 14px
		align-items: center

		svg
			margin: 0 5px

		p
			margin: 0 5px 0 5px
