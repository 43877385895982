.contract-representativeness-widget

	.ant-table-thead

		tr

			.ant-table-cell
				background: #F3F6F9 !important
				height: 2.5rem
				border: none

				&:before
					content: none !important

				&:first-child
					border-top-left-radius: 6px
					border-bottom-left-radius: 6px

				&:nth-last-child(2)
					border-top-right-radius: 6px
					border-bottom-right-radius: 6px

			.ant-table-cell-scrollbar
				background: none !important

	.ant-table-body
		overflow-y: auto!important

		.ant-table-tbody
			color: #717171
			font-weight: 600

			tr
				td
					border: none

				td:first-child
					font-weight: 450
