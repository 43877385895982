.open-filter-button
	display: flex
	align-items: center
	justify-content: space-between
	cursor: pointer
	height: 56px
	line-height: normal
	max-width: 230px
	padding: 24px 8px
	&.disabled-button
		cursor: not-allowed
		background: #f1f1f1
	&:not(:last-child)
		border-right: 2px solid #ccc
	p.current-filter-value
		font-size: 13px
		margin: 0
	p.filter-name
		color: #072D49
		font-weight: 500
		margin: 0
	svg.open-filter-icon
		font-size: 18px
		min-height: 45px
	.ant-picker
		background-color: #fff
		padding: 0
		input
			font-size: 13px
			font-weight: normal
		.ant-picker-active-bar
			margin-left: 0
			max-width: 50px
	.gs-date-content
		gap: 0
		padding: 0
		background-color: #fff
		height: 22px
		margin-right: 20px
		svg.gs-date-icon
			display: none
		svg.gs-date-separator
			margin: 4px
			margin-bottom: -6px
		input
			font-weight: normal
			font-size: 13px
			text-align: center
			margin-bottom: -8px
			&:hover
				cursor: pointer
				border-bottom: 2px solid #1890ff

.switch-icon
	border: 1px solid #ccc
	color: #4F9ACF
	margin: 0 15px 0 0
	font-size: 22px
	padding: 6px
	border-radius: 4px
	width: 40px
	height: 40px

.ant-popover-inner[id^="budget-container-"]
	.ant-popover-inner-content
		padding: 0

.search-list-filter
	padding-bottom: 10px
	svg
	color: #4F9ACF

.ant-list-item:last-child
	border-bottom: none

.filter-list-container
	user-select: none
	max-width: 300px
	min-width: 200px
	display: flex
	flex-direction: column
	align-items: center
	justify-content: flex-start
	padding-bottom: 20px
	.ant-spin
		margin: 40px 0 30px
	ul
		max-height: 200px
		overflow: auto

.filter-header
	width: 100%
	padding: 20px 15px 5px
	border-top-left-radius: 9px
	border-top-right-radius: 9px
	span:last-child
		color: #0065B3

.filter-list
	max-height: 40vh
	overflow: auto
	width: 100%
	padding: 5px
	border-bottom-left-radius: 9px
	border-bottom-right-radius: 9px
	.ant-checkbox-wrapper-checked,
	.ant-radio-wrapper-checked
		background-color: #E5EFF6
	.ant-checkbox-wrapper.ant-checkbox-group-item
		padding: 5px 10px
	.ant-radio-wrapper
		width: 100%
		padding: 5px
	label
		margin: 2.5px 0 !important
		&:first-child
			margin-top: 0 !important
		&:last-child
			margin-bottom: 0 !important


.filter-values-container
	display: flex
	align-items: center
	justify-content: flex-start
	width: 150px
	text-overflow: ellipsis
	overflow: hidden

.current-filter-value
	max-height: 20px
	margin: 0 !important
	text-overflow: ellipsis
	overflow: hidden
	white-space: nowrap

.filter-footer
	display: flex
	align-items: center
	justify-content: center
	width: 100%
	padding: 10px 20px
	background: #fff
	border-bottom-left-radius: 9px
	border-bottom-right-radius: 9px

.ant-dropdown
	border: 1px solid #D7D7D7 !important
	box-shadow: 0px 4px 4px 0px #00000040 !important
	border-radius: 9px
