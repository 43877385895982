@import "components/variable"

.ant-layout-content
	gap: 3px
	display: flex
	flex-direction: column

.widgets-main
	display: flex
	margin-top: 7px
	flex-direction: column

	> button
		margin: 0 0 -3px 17px
		background: #FFFFFF !important
		border-radius: 4px 4px 0 0
		color: $font-blue-color !important
		font-size: 1.3rem

		svg
			font-size: 1.3rem

	> button:hover
		color: $font-blue-color !important

.widget-collapse
	display: flex
	flex-direction: column

	.widget-content
		background: #FFFFFF
		border: 1px solid #F3F3F3
		border-radius: 6px
		font-size: 1rem
		color: #424242

	.widget-content:not(:first-child)
		margin-top: 0.6rem

	.ant-collapse-header
		display: flex !important
		align-items: center !important
		gap: 5px

	.ant-collapse-header
		display: flex
		align-items: center
		gap: 0.4rem

		svg
			font-size: 1.3rem
			color: $font-blue-color

	.ant-collapse-content

		.ant-collapse-content-box

			.widget-header-actions-container
				width: 100%
				display: inline-flex
				justify-content: flex-end
				align-items: center
				gap: 10px
				margin-bottom: 10px

			.loading-container
				width: 100%
				display: flex
				flex-direction: row
				justify-content: center
				color: #bfbfbf
				padding: 185px 0
				font-size: 40px

			.ant-space,
			.ant-space > .ant-space-item
				width: 100%

.widget-popover-container
	display: flex
	flex-direction: column
	gap: 0.7rem
	align-items: center
	width: 100%