.organization-list-tab
    width: 100%
    .ant-tabs-tab
        margin-right: 2px

.main-button-dropdown
    border-radius: 5px 0 0 5px !important

.custom-tab
    .ant-tabs-tab
        width: 50%
        margin-left: 16px

    .ant-tabs-nav-wrap
        display: flex
        flex-wrap: wrap
    .ant-tabs-nav-list
        width: 75%
        margin-left: 1rem

.organization-panel-container
    .organization-panel-header
        display: flex
        align-items: center
        width: 100%
        border: 1px solid #d9d9d9
        padding: 12px 16px
        margin-bottom: 16px
        h3
            margin: 0 8px
        .organization-panel-year-box
            width: 100%
            margin: 16px 0 0

.static-info-panel
    margin: 1rem

.period-selector
    display: flex
    flex-direction: column

.selector-container
    display: flex

.month-select
    width: 20%
    margin: 2px 1rem 30px 0

.organization-panel-year-box
    margin-top: 1rem
