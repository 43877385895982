.scenarios-modal
  form
    width: 100%
    .fields-container
      width: 100%
      display: flex
      gap: 1.5rem

      .input-content
          margin-right: 0
          margin-bottom: 5px
          width: 21.3125rem

      .ant-row.ant-form-item-row
          display: block

      .input-indexer
          width: 38.5rem

      .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before
          display: none

      .ant-form-item-label > label
          height: auto !important
          color: #5C5C5C
          font-size: 12px
          line-height: 16px

      .ant-picker
          border-radius: 2px
          width: 100%
          border: 1px solid #d9d9d9
          background-color: white
          input
              font-weight: 400

  .scenario-modal-table-container
    display: flex
    flex-direction: column
    align-items: flex-end
    gap: 0.25rem

    .search-business-unit
      width: 200px