.filter-modal
  .ant-modal-body
    padding: 0px !important

  .filter-form
    .top-container
      padding: 1.5rem 1rem 0px
      display: flex
      gap: 3.875rem
      align-items: center
      
      .dates-wrapper
        display: flex
        align-items: center
        .ant-picker
          border-radius: 2px
          width: 11.75rem
          border: 1px solid #d9d9d9
          background-color: white
          input
              font-weight: 400

        .divider
          margin: 0px 7px
          border-top: 1px solid #D7D7D7
          width: 17px
      
      .checkboxes-wrapper
        display: flex
        align-items: center
        gap: 4rem
        margin-top: 1.6875rem
  
      .input-content
          margin-right: 0
          margin-bottom: 5px

      .input-indexer
          width: 39.125rem

      .ant-form-item
          display: block !important

      .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before
          display: none

      .ant-form-item-label > label
          height: auto !important
          color: #5C5C5C
          font-size: 0.75rem
          line-height: 1rem

      .ant-form-item-label > label
          height: auto !important
          color: #5C5C5C
          font-size: 0.75rem
          line-height: 1rem

    .content
      display: flex
      width: 100%
      
      .table-wrapper
        width: 100%
        max-width: 61rem
        padding: 0px 0.5rem 0.5rem
        border: 1px solid #D7D7D7
        border-left: none
        overflow-y: scroll

        .ant-form-item
          margin: 0px !important

        .table-search-input
          width: 100%
          max-width: 61rem
          margin: 0.5rem 0px
          border-radius: 0.25rem

      .button-column 
        width: 14.5625rem
        height: 29.1875rem
        background-color: #FCFCFC
        padding: 1rem
        gap: 0.5rem
        display: flex
        flex-direction: column
        border: 1px solid #D7D7D7

        button
          width: 12.5rem
          display: flex
          padding: 0 0.5rem
          color: black
          font-style: normal
          font-weight: 400
          font-size: 14px
          color: #424242
          justify-content: space-between
          align-items: center
          .counter 
            color: #0065B3
            font-weight: 700
            line-height: 1rem

        .divider 
          height: 1px
          border: none
          border-top: 1px solid #D7D7D7
          margin: 16px 0
        