@import "components/variable"

#analytics-container
	.ant-card
		margin-top: 10px

		.ant-card-head
			min-height: 0
			border-bottom: none
			padding: 0 10px

			.ant-card-head-wrapper

				.ant-card-extra,
				.ant-card-head-title
					padding: 0

		.ant-card-body
			padding: 0 10px

			.ant-table-content

				table
					border-spacing: 0 10px

					.ant-table-thead

						tr

							.ant-table-cell
								padding: 0
								border: none
								text-align: center
								background: $light
								color: $font-primary-color

								&::before
									display: none

								&:not(:last-child)
									padding-right: 10px

					.ant-table-tbody

						.ant-table-row

							td
								background: #F2F2F2
								padding: 0
								border-bottom: none

								&:first-child
									border-top-left-radius: 9px
									border-bottom-left-radius: 9px
									text-indent: 10px

								&:not(:last-child)
									padding-right: 10px

								&:last-child
									border-top-right-radius: 9px
									border-bottom-right-radius: 9px

	.hidden-asider

		.ant-card-body,.ant-card-head
			opacity: 0
			transition: opacity 0.5s ease-in

	.show-asider

		.ant-card-body,.ant-card-head
			transition: opacity 0.5s ease-in
			opacity: 1
