.premisses-table-container
    margin: 6px 24px
    .gs-table
        .ant-table-container
            border-left: none

        .editable-cell
            position: relative
        .ant-table-selection-column
            padding: 6px
        tbody
            .editable-value-cell:hover
                background: #E8F2F9
        .editable-value-cell
            padding: 0px
            cursor: pointer
            .ant-form-item
                padding: 0 !important

        .editable-row:hover .editable-cell-value-wrap
            border: 1px solid #d9d9d9
            border-radius: 2px

        input
            max-width: 56px
            margin: 0
            padding: 2px

        .premisses-table-input-cell
            display: flex
            align-items: center
            justify-content: center
            position: relative
            .premisses-replicate-buttons-container
                opacity: 0
                display: none
                transition: all 0.2s
                align-items: center
                justify-content: space-between
                position: absolute
                &.left
                    left: 0
                &.right
                    right: 0
                svg
                    font-size: 18px
                    color: #333
                    cursor: pointer
                    background: #fff
            &:hover
                .premisses-replicate-buttons-container
                    opacity: 1
                    display: flex

    .ant-table-summary
        .ant-table-cell
            padding: 6px !important

    .ant-table-content
        table
            border-top: none !important

    .ant-table-row
        background-color: #fff !important

    .ant-table-cell
        svg:hover
            cursor: pointer

.premisses-spin-container
    width: 100%
    height: calc(100vh - 200px)
    display: flex
    align-items: center
    justify-content: center
.premisses-save-alert-container
    display: flex
    align-items: center
    justify-content: end
    margin: 0 24px
    .successfully-saved-budget-alert
        background: rgba(46, 182, 104, 0.12)
        border: none
        transition: all 0.3s ease 0s
        border-radius: 3px
        padding: 1px 15px 1px 10px
        svg
            color: #2EB668
            font-size: 20px
            .ant-alert-message
                color: #2EB668

.premisses-filters-container
    display: flex
    align-items: center
    justify-content: space-between
    margin: 24px 24px 6px
    border-bottom: 1px solid #ccc
    padding-bottom: 20px
    .ant-carousel
        width: calc(100% - 600px)
    .premisses-filters-settings
        border: none
        margin-left: 30px
        color: #333
        width: 40px
        font-size: 24px
        cursor: pointer

.premisses-filters-settings-popover-container
    .ant-popover-title
        padding: 15px 16px 0px
    .premisses-filters-settings-popover-content
        display: flex
        align-items: start
        justify-content: flex-start
        width: 300px
        .ant-checkbox-group
            display: flex
            flex-direction: column
            padding-bottom: 10px
