@import "components/variable"

#attribute_parameterization-container
	font-family: 'Open Sans', sans-serif
	background: #fff
	padding: 0 25px 0 25px

	#attribute_parameterization-title
		display: flex
		width: 100%
		justify-content: space-between

		div.ant-picker.ant-picker-range
			width: 100%

		p.filter-name
			color: #5C5C5C
			margin-right: 4px

			&::after
				content: ":"
		div.ant-picker-input
			width: 60px

		div.ant-picker-input input
			color: #5C5C5C

		div.ant-picker-range-separator
			width: 25%
			text-align: center

	.ant-layout-header
		margin: 0
		padding: 0
		display: flex
		justify-content: space-between

		.open-filter-button
			max-width: none

			div.ant-space.ant-space-vertical
				flex-direction: row
				padding: 0
				margin: 0
				height: 100%
				align-items: center

			.ant-picker.ant-picker-range
				width: 220px

		.ant-space#attribute_parameterization-header-title
			h3
				font-weight: 400
				color: #424242

			.ant-space-item
				width: auto

				.coachmark-button
					border-radius: 4px
					margin-top: 25px
					margin-left: 10px

					svg
						font-size: 26px
						color: #0065B3

	#attribute_parameterization-content-container
		flex-direction: column

		#attribute_parameterization-content-query-bar
			display: flex
			justify-content: space-between
			width: 100%

		#attribute_parameterization-new_rule_button
			background: #0065B3
			margin-right: 23px
			display: inline-flex
			justify-content: center
			align-items: center
			gap: 6px

			&[disabled]
				opacity: 0.7
				cursor: not-allowed

		#attribute_parameterization-option-buttons
			display: flex

			#lastRealizedMonth
				background: rgba(46, 182, 104, 0.12)
				color: #2EB668
				font-weight: 400
				font-size: 0.85rem
				line-height: normal
				padding: 4px 8px
				margin-left: 16px
				border-radius: 4px

	#attribute_parameterization-content-main
		display: flex
		width: 100%
		margin-top: 20px
		gap: 16px
	
		.ant-table-wrapper.gs-table .ant-table.ant-table-ping-right.ant-table-scroll-horizontal
			width: 100%

		.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box
			padding: 0 !important

		.ant-table-wrapper.gs-table
			width: 80%

			.ant-table-container
				width: 100%

				.ant-table-content table
					width: 100%

					.ant-table-row td:last-child
						button
							display: none

					thead.ant-table-thead th.ant-table-cell
						text-align: center
						min-width: 90px

					.tax-btn-copy
						display: inline
						border: none
						outline: none
						box-shadow: none
						background: transparent

						&:hover
							background-color: transparent
							box-shadow: none
							border: none

					.tax-btn-copy-disabled
						display: none
						border: none
						outline: none
						box-shadow: none

					tbody.ant-table-tbody td.ant-table-cell
						min-width: 80px
						text-align: center
						font-size: 14px
						line-height: 16.34px
						height: 45px

			.ant-table.ant-table-ping-right.ant-table-scroll-horizontal

		#attribute_parameterization-content-atributes-table
			th.ant-table-cell
				background: #44759A
				color: #FFFFFF
				font-weight: 600
				border: 1px solid #fff
				padding: 4px 30px
				text-align: center

			td.ant-table-cell
				border: 1px solid #E2E2E2
				padding: 8px 15px

			tr.ant-table-row
				&:last-child
					td.ant-table-cell:first-of-type
						border-bottom-left-radius: 4px
					td.ant-table-cell:last-child
						border-bottom-right-radius: 4px

		#attribute_parameterization-content-atributes-section
			border: 1px solid #E2E2E2
			background: #FCFCFC
			width: 20%
			height: 680px
			overflow: auto

			@media (max-width: 1400px)
				height: 380px
				width: 17%
				overflow: auto

			.ant-space-item:nth-child(1)
				padding: 8px 8px 0 8px

			.ant-space-item:nth-child(2)
				display: flex
				justify-content: center
				align-items: center
				margin-top: 10px
				margin-bottom: 10px

			.ant-collapse-header[aria-expanded=true]
				background: #EFEFEF

			#attribute_parameterization-new-attribute-button
				display: flex
				justify-content: center
				align-items: center
				background: #FCFCFC
				width: 90%
				border: 1px solid #0D2F48
				color: #0D2F48
				outline: none
				box-shadow: none
				gap: 8px
				padding: 16px
				padding-left: 16px
				font-weight: 600
				text-shadow: none
				transition: background-color 0.7s

				&:hover
					background-color: #0D2F48
					color: #fff

		.attribute_parameterization-new-attribute-panel
		.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box
			padding: 0 6px 0 6px

		.attribute_parameterization-new-attribute-panel .ant-collapse-header
			background: #EFEFEF
			color: #3F3E44

		.attribute_parameterization-attribute-option
			display: flex
			justify-content: space-between
			align-items: center
			width: 100%
			padding: 8px 0 8px 8px
			cursor: pointer

			svg
				font-size: 20px
				color: #717171
			span
				color: #3F3E44
				padding-left: 10%

		.attribute_parameterization-attribute-option-active
			background: #E8F2F9

.attribute_parameterization-new-attribute-modal
	.ant-modal-footer
		border-top: none

		button.ant-btn.ant-btn-primary
			&[disabled]
				background: #F5F5F5
				border: 1px solid #DBDBDB
				color: #ADADAD
			&[disabled]:hover
				background: #F5F5F5 !important

	.operations-select-container
		display: flex

		.operations-select-buttons
			display: flex
			justify-content: flex-start
			gap: 40px
			margin-top: 0.3rem
			width: min-content

	.ant-modal-header
		border-radius: 8px 8px 0 0

	.ant-modal-content
		border-radius: 8px

	.ant-modal-body
		padding: 0

	#attribute_parameterization-new-attribute-modal-content-wrapper
		padding: 0 24px 0 24px

	#attribute_parameterization-new-attribute-modal-formula
		padding-top: 1rem
		padding-bottom: 1rem

		h3
			font-weight: 400
			color: #3F3E44

		.attribute_parameterization-new-attribute-modal-formula-workarea
			display: flex
			align-items: center
			justify-content: flex-start
			border: 1px solid #D7D7D7
			border-radius: 3px
			background: #FCFCFC
			padding: 10px
			gap: 8px
			min-height: 60px
			flex-wrap: wrap

			.attribute_parameterization-new-attribute-modal-formula-workarea-tag
				position: relative
				background: #E8F2F9
				line-height: 16px

				span
					position: absolute
					top: -20%
					right: -10%
					padding: 2px
					background: #DBDBDB
					color: #5C5C5C
					border-radius: 50%
					font-size: 10px
					cursor: pointer
					visibility: hidden

			.attribute_parameterization-new-attribute-modal-rule
				display: flex
				justify-content: center
				align-items: center
				border: 1px solid #D7D7D7
				border-radius: 3px
				background: #FCFCFC
				height: 34px
				width: fit-content
				padding-left: 16px
				box-sizing: border-box
				gap: 11px

				.attribute_parameterization-new-attribute-modal-rule-label
					display: flex
					justify-content: center
					align-items: center
					padding: 1px 8px
					height: 18px
					background: #E8F2F9
					font-weight: 400
					color: #0065B3
					border-radius: 2px

				.attribute_parameterization-new-attribute-modal-rule-options

	#attribute_parameterization-new-attribute-modal-add-button-wrapper
		display: flex
		justify-content: flex-end
		margin-top: 16px

		button
			display: flex
			justify-content: center
			align-items: center
			background: transparent
			outline: none
			box-shadow: none
			color: #0D2F48
			border: 1px solid #0D2F48

	#attribute_parameterization-new-attribute-modal-form
		display: grid
		grid-template-columns: repeat(3, minmax(0, 3fr))
		gap: 1.5rem
		padding-top: 1rem
		padding-bottom: 1rem

		@media (max-width: 640px)
			grid-template-columns: repeat(2, minmax(0, 1fr))

		@media (max-width: 768px)
			grid-template-columns: repeat(3, minmax(0, 1fr))

		.gs-input-label
			font-weight: 400
			color: #5C5C5C

.attribute_parameterization-new-rule-modal
	.ant-modal-header
		border-radius: 8px 8px 0 0

	.ant-modal-content
		border-radius: 8px

	.ant-modal-title
		color: #424242
		font-weight: 400
		font-size: 18px
		line-height: 22.5px

	.ant-modal-body
		padding: 0

	.ant-modal-footer
		border-top: none

		button.ant-btn.ant-btn-primary
			&[disabled]
				background: #F5F5F5
				border: 1px solid #DBDBDB
				color: #ADADAD
			&[disabled]:hover
				background: #F5F5F5 !important

	#attribute_parameterization-new-rule-modal-conditions-container
		display: flex
		justify-content: space-between
		align-items: center

		#attribute_parameterization-new-attribute-modal-conditions-wizard-counter
			display: flex
			gap: 7px
			margin-right: 16px

			.rounded-step
				display: inline-block
				width: 12px
				height: 12px
				border-radius: 50%
				cursor: pointer
				background: #D9D9D9
				border: 0

			.rounded-step.active-rounded-step
				background: #0065B3

			.rounded-step.inactive-rounded-step
				background: #D9D9D9

			.rounded-step.blocked-rounded-step
				background: #D9D9D9
				cursor: not-allowed

		#attribute_parameterization-new-attribute-modal-conditions-title
			display: inline-block
			font-size: 1.1rem
			font-weight: 400
			color: #424242
			margin-left: 26px
			margin-top: 6px
			margin-bottom: 0
			border-bottom: 2px solid #045594

	.ant-divider-horizontal
		margin-top: 0
		margin-bottom: 16px

	#attribute_parameterization-new-rule-modal-content-wrapper
		padding: 0 24px 0 24px

	#attribute_parameterization-new-rules-modal-rules-section
		padding: 0 24px 24px 24px

		h3
			font-weight: 400
			color: #3F3E44

		.attribute_parameterization-new-attribute-modal-rules-workarea
			display: flex
			flex-direction: column
			border: 1px solid #D7D7D7
			border-radius: 3px
			background: #FCFCFC
			padding: 10px
			gap: 8px
			min-height: 123px
			max-height: 123px
			overflow: auto

			.attribute_parameterization-new-attribute-modal-rule
				display: flex
				justify-content: center
				align-items: center
				border: 1px solid #D7D7D7
				border-radius: 3px
				background: #FCFCFC
				height: 34px
				width: fit-content
				padding-left: 16px
				box-sizing: border-box
				gap: 11px

				.attribute_parameterization-new-attribute-modal-rule-label
					display: flex
					justify-content: center
					align-items: center
					padding: 1px 8px
					height: 18px
					background: #E8F2F9
					font-weight: 400
					color: #0065B3
					border-radius: 2px

				.attribute_parameterization-new-attribute-modal-rule-options

	#attribute_parameterization-new-attribute-modal-add-button-wrapper
		display: flex
		justify-content: flex-end
		margin-top: 16px

		button
			display: flex
			justify-content: center
			align-items: center
			background: transparent
			outline: none
			box-shadow: none
			color: #0D2F48
			border: 1px solid #0D2F48

			&:disabled
				background: #F5F5F5
				border: 1px solid #DBDBDB
				color: #ADADAD

	.attribute_parameterization-new-rule-modal-form
		display: grid
		grid-template-columns: repeat(3, minmax(0, 1fr))
		gap: 1.5rem
		padding-top: 1rem

		@media (max-width: 640px)
			grid-template-columns: repeat(2, minmax(0, 1fr))

		@media (max-width: 768px)
			grid-template-columns: repeat(3, minmax(0, 1fr))

		.gs-input-label
			font-weight: 400
			color: #5C5C5C

	.attribute_parameterization-new-rule-modal-form-df
		display: grid
		grid-template-columns: repeat(4, minmax(0, 1fr))
		gap: 1.5rem
		padding-top: 1rem

		@media (max-width: 640px)
			grid-template-columns: repeat(2, minmax(0, 1fr))

		@media (max-width: 768px)
			grid-template-columns: repeat(3, minmax(0, 1fr))

		.gs-input-label
			font-weight: 400
			color: #5C5C5C

	.attribute_parameterization-new-rule-modal-info-apportionment
		& > h3
			font-weight: 400
			margin-bottom: 0
			font-size: 14px
			color: #5C5C5C

		.attribute_parameterization-new-attribute-modal-formula-workarea
			display: flex
			align-items: center
			margin-top: 30px
			margin-bottom: 30px
			border-radius: 2px
			border: 1px solid #d9d9d9
			background-color: #FCFCFC
			min-height: 54px
			padding: 12px
			flex-wrap: wrap
			gap: 8px

	.attribute_parameterization-new-rule-modal-info-formula
		display: grid
		grid-template-columns: repeat(2, minmax(0, 1fr))
		gap: 1.5rem
		padding-top: 1rem
		padding-bottom: 1rem

		.gs-input-label
			font-weight: 400
			color: #5C5C5C

	.new-rule-info-operations-select
		label
			margin-bottom: 5px
		div.operations-select-buttons
			display: flex
			gap: 25px
