.period-board-container
    display: flex
    align-items: center
    justify-content: center
    margin: auto
    gap: 16px
    padding: 4px
    flex: 1
    background: none
#revision-container
    padding: 8px
    .revision-content
        display: flex
        align-items: center
        &:not(:first-child)
            margin-top: 8px
        svg
            margin: 0 16px
    p
        margin: 0
