.registration-sales-page
	.caption-level
		padding-left: 40px
		position: relative
	.caption-level::before
		content: ''
		height: 43px
		width: 1px
		position: absolute
		top: -9px
		background-color: #D7D7D7
	.header-content
		align-items: flex-start
		flex-direction: column
		.icon-copy
			transform: scaleX(-1)
	.new-report-page
		.report-planning-line-filter
			flex-direction: column
			.ant-col
				display: flex
				.ant-form-item
					width: 100%
			.signal-filter
				width: auto
				&>div
					display: flex
					justify-content: flex-end
					align-items: center
					p
						margin: 0
				.gs-secondary-button
					padding-left: 4px
					margin-right: 20px
					border: none
					width: auto
					justify-content: flex-start
					height: auto
		.attribute_parameterization-new-rule-modal-form
			.ant-row
				width: 100%
		.page-title-content
			h1
				width: -webkit-fill-available
			.ant-steps-item-container
				display: flex
				align-items: center
				justify-content: center
				.ant-steps-item-icon
					display: flex
					align-items: center
					justify-content: center
			.site-navigation-steps
				.ant-steps-item-active
					.ant-steps-item-icon
						background: #0065B3
						border-color: #0065B3
					&::before
						background-color: #0065B3
					span
						position: unset
		.button-steps
			display: flex
			justify-content: end
			margin: 15px 35px
		main
			padding: 16px
			background: #F1F2F3
		.step-one
			border-radius: 9px
			background: #fff
			padding: 15px

			.ant-table-body
				overflow: auto !important
			table
				border-bottom: 1px solid #f0f0f0
				.ant-table-tbody
			.ant-table-tbody
				tr:not(:last-child)
					td
						border-bottom: none
			.ordination-column
				align-items: center
				justify-content: center

				.ant-col:nth-child(2)
					position: absolute
					right: 0

				button
					padding: 0
					height: fit-content
					font-size: 1px
					svg
						opacity: 0
				&:hover
					svg
						opacity: 0.8
			.bold
				font-weight: bold
			.italic
				font-style: italic
			.underlined
				text-decoration: underline
			.scratched
				text-decoration: line-through
			tr.scratched.underlined
				text-decoration: line-through underline
			.description-report
				width: 50%
			.ant-table-cell-row-hover
				background-color: unset
			.button-expandable-tree
				width: 25px
				height: 25px
				float: left
				position: relative
				cursor: pointer
				user-select: none
				text-align: center
				font-size: 15pt
				color: #717171
				line-height: 25px
				.checkbox-invisible
					justify-content: center
					display: flex
					width: 100%
			.gs-top-buttons
				.top-buttons-left-content
					margin: 15px 0px !important
		div.step-one.step-two
			main
				border: 1px solid #E9E9E9
				padding: 16px
				border-left: 16px solid #E9E9E9
				background: #fff
		.step-two
			border-radius: 9px
			background: #fff
			padding: 15px
			.top-tool-buttons
				border: 0
				background: transparent
				outline: none
				box-shadow: none
			.ant-select-show-search
				.ant-select-selection-overflow
					flex-wrap: nowrap
					overflow-x: auto
			.attribute_parameterization-new-rule-modal
				.ant-collapse-content-box
					display: flex
					flex-direction: column
					gap: 12px
			.attribute_parameterization-new-attribute-modal-add-button-wrapper
				margin-bottom: 10px
			.ant-btn-icon-only
				display: flex
				justify-content: center
				align-items: center
				p
					margin: 0
					display: felx
					align-items: center
					justify-content: center
					font-family: math
					font-weight: 600
			.attribute_parameterization-new-attribute-modal-formula-workarea
				display: flex
				height: 250px
				border: 1px solid #D7D7D7
				background: #FCFCFC
				padding: 10px 10px 54px
				margin-bottom: 11px
				flex-wrap: wrap
				align-content: flex-start
				gap: 10px
				overflow-y: auto
				.attribute_parameterization-new-attribute-modal-formula-workarea-tag
					background: #E8F2F9
					border: none
					height: 26px
					&:hover
						cursor: grab
					&:active
						cursor: grabbing
				&>div
					height: 26px
					margin-right: 4px
					input
						height: auto
			.form-field
				margin-top: 10px
				.operations-select-buttons
					width: 100%
					border: 1px solid #DBDBDB
					padding: 12px
					border-radius: 3px
			.footer-buttons
				display: flex
				position: absolute
				right: 10px
				bottom: 10px
			.visualization-formula
				margin-top: 10px
				position: relative
				.header-buttons
					display: flex
					justify-content: space-between
			.ant-layout-sider
				max-height: 595px
				overflow-y: auto
				border: 1px solid #DBDBDB99
				.ant-table-title
					border-bottom: 1px solid #DBDBDB99
			.attribute_parameterization-new-attribute-modal-rules-workarea
				flex-direction: row
				flex-wrap: wrap
			#attribute_parameterization-new-rules-modal-rules-section
				padding: 0

				.ant-collapse-header
					svg
						color: #333333
						font-size: 20px
			.ant-form
				display: flex
				justify-content: center
				gap: 15px
				.select-value-filter
					max-width: 250px
				.form-field
					width: 30%
			.gs-main-button
				margin-bottom: 8px
				svg
					color: inherit !important
			.ant-layout
				background: #fff
				display: flex
				flex-direction: row
				gap: 10px
				overflow-y: auto
				max-height: 650px
			.select-level-table
				.selectable-row
					position: relative
					cursor: pointer
				.disabled-row
					background: #F5F5F5 !important
					color: #424242 !important
					cursor: not-allowed
				.description-column
					display: flex
					justify-content: space-between
					align-items: center
					flex-wrap: nowrap
				.selected-row
					height: 100%
					position: absolute
					right: -21px
					display: flex
					align-items: center
					font-size: 32px
					background: #DBDBDB
					width: 21px
				.is-selected
					background: #DBDBDB !important
					td
						border-bottom: none
						background: transparent
			.options-list
				.options-list-content
					border: 1px solid #DBDBDB
					.ant-input-group-wrapper
						padding: 10px
					.ant-checkbox-group
						padding: 10px
						overflow-y: auto
						max-height: 400px
						.ant-checkbox-wrapper
							padding: 10px 5px
							margin: 0
				.footer
					color: #9D9D9D
					border-top: 1px solid #DBDBDB
					width: 100%
					display: flex
					padding: 10px
		.step-three
			.formula-column
				padding-left: 12px
.overlay-color-picker-report
	.custom-select
		.gs-input-label
			color: #5C5C5C
			font-weight: unset
		.ant-select-selector
			height: 25px
			margin: 0
			border: 1px solid #BBBBBB
			border-radius: 3px
			padding-left: 5px
			.ant-select-selection-item
				display: flex
				align-items: center
	.overlay-color-picker
		cursor: default
		background: #fff
		width: 250px
		height: 270px
		.header-content
			border-bottom: 1px solid #DBDBDB
			margin-bottom: 20px
		h4
			margin: 6px
		.color-picker-button
			display: flex
			flex-direction: column
			align-items: start
			justify-content: start
			width: 100%
			p
				margin: 0
				color: #5C5C5C
			button
				padding: 0
		.color-selected
			display: flex
			align-items: center
			justify-content: center
			width: 100%
			svg
				width: 20px
				border-radius: 0px 3px 3px 0px
				border-right: 1px solid #BBBBBB
				margin-right: 2px
				padding: 0 3px
			div
				border-radius: 3px 0px 0px 3px
				border-left: 1px solid #BBBBBB
				width: 100%
			div, svg
				height: 25px
				border-top: 1px solid #BBBBBB
				border-bottom: 1px solid #BBBBBB
		.font-style-content
			background: #DBDBDB
			border-radius: 6px
			height: 28px
			padding: 3px 4px
			gap: 4px
			.ant-col
				display: flex
				align-items: center
				justify-content: center
				height: 23px
				width: 23px
				border-radius: 2px
				button
					height: -webkit-fill-available
					padding: 0
					margin: 0
			.active
				background: #fff
		.footer-popover
			margin-top: 50px
			justify-content: end
.description-column-registration-sales
	&:first-child
		background: red !important
		border-bottom: none !important
.new-report-modal
	.new-report-header
	.new-report-content
		align-items: center
		justify-content: center
		.ant-col
			height: 70px
			width: 50px
		button
			height: auto
			height: auto
			display: flex
			flex-direction: column
			align-items: center
			justify-content: center
			width: 200px
			svg
				font-size: 50px
				color: #004D7B
		.type-selected
			opacity: 0.6
	.ant-modal-footer
		display: flex
		justify-content: end
.sinal-type-content
	width: 120px
	.ant-popover-inner
		border: none !important
		box-shadow: 0px 4px 11px 0px #6B6B6B40 !important
	.ant-popover-inner-content
		width: 100%
		display: flex
		flex-direction: column
		padding: 0
		.sinal-selected
			background: #E8F2F9
		button
			width: 100%
			display: flex
			align-items: center
			justify-content: space-between
			p
				margin: 0
