#main-menu-container
  position: absolute
  top: 0
  height: 100vh
  background: #0D2F48
  z-index: 100

  .ant-input-affix-wrapper
    input
      background: #081c2b
      color: #fff
  .input-freemium
    background-color: #004C7A !important
    input
      background-color: #004C7A !important

#main-menu-content
  margin: 20px 0
  background: #0D2F48
  color: #fff
  .ant-menu-item
    &:hover 
      background: #134a72
  .ant-menu-item-icon
    width: 20px
    color: #fff 
  a
    color: #fff 
    &:hover 
      color: #fff 
 

#input-search-main-menu 
 background: #081c2b

#g-icon
  color: #fff
  font-size: 40px
  cursor: pointer